import React, {useEffect} from "react"
import { goToEntrevista } from '../../lib/Redireccion';
import { Col, Row } from "react-bootstrap";
import './404Page.css';
// imagen
import advertenciaIcon from '../images/advertenciaIcon.svg';

export default function PageNotFound() {
    
    const goToLogin = () => {
        setTimeout(() =>{
            goToEntrevista();
        },5000);
    }

    useEffect(() => {
        goToLogin()
    }, []);

    return (
        <React.Fragment>
            <div className="contenedor-no-found container">
                <Row className="div-no-found">
                    <Col sm={12} className="text-center">
                        <img className="div-no-found__imagen" src={advertenciaIcon} alt=""/>
                        <p className="div-no-found__advertencia div-no-found__mensaje--Bold">Pagina no encontrada</p>
                        <p className="div-no-found__mensaje">Te redireccionaremos al dashboard.</p>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    )

}