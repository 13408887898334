import React from "react";
import { Card, Col, ListGroup, Row, Button, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import StarRatings from 'react-star-ratings';
import TextoEnrriquecido from '../../textoEnrriquecido/TextoEnrriquecido';
import { EditorState, ContentState } from "draft-js";
import htmlToDraft from 'html-to-draftjs';
import { convertFromRaw } from 'draft-js';
import { _NUM_MAX_TEXT } from "../../../settings";
import { stateFromHTML } from 'draft-js-import-html';
import { NotificationContainer, NotificationManager } from 'react-notifications';
// ejecutor axios
import axiosExcecute from '../../../lib/peticionApi';
// encabezados para peticiones
import { _SERVER_GUIA_ENTREVISTA, _SERVERBRIVE } from '../../../settings';
import axios from 'axios';
// ejecutor axios
import axiosExcecuteBerchmark from '../../../lib/peticionApiBerchMark';
// obtenemos los tokens
import { GetTokenBrive, SaveCookeSession, GetTokenGuiaEntrevista, GetAuth } from '../../../lib/cookieSession';
// objetos fetch
import { HeaderGet } from '../../../lib/fecthObject';
// creamos el manejador de cookies
import Cookies from 'universal-cookie';
import { message } from 'antd';
// libreria para actualizar token
import { refreshTokenBrive } from '../../../lib/refreshTokenBrive';

import SaveProcess from "./SaveProcess";
import RowFlex from "./RowFlex";
import ColFlex from "./RowFlex/ColFlex";

const cookies = new Cookies();
const content = { "entityMap": {}, "blocks": [{ "key": "637gr", "text": "Initialized from content state.", "type": "unstyled", "depth": 0, "inlineStyleRanges": [], "entityRanges": [], "data": {} }] };
var detectrtc = require('detectrtc');
class Preguntas extends React.Component {

    constructor(props) {
        const contentState = convertFromRaw(content);
        super(props);
        this.state = {
            tipoPreguntas: 0,
            todasPreguntasProfundas: [],
            preguntasProfundasActuales: [],
            paginasPreguntasProfundas: 0,
            paginaPreguntaProfundaActual: 1,
            editorState: null,
            todasPreguntasCompetencias: [],
            preguntasCompetenciasActuales: [],
            paginasPreguntasCompetencias: 0,
            paginaPreguntaCompetenciaActual: 1,
            validarNumeros: "",
            form: {
                Texto: "",
            },
            contentState,
            deepInterview: [],
            competenceInterview: [],
            answerDeepInterview: [],
            answerCompetenceInterview: [],
            headerCompetencia: {
                id: 0,
                name: "",
                perfilDeseado: 0,
                talentoEvaluates: 0,
                score: 0
            },
            headerCompetenciasList: [],
            showSpiner: false,
            isChangeDeepQuestion: false,
            isChangeCompetenceQuestion: false,
            Ip: "",
            saveProgress: {
                loading: false,
                save: false,
            },
            contadorIntentosBerchMark: 0,
            datosBerchMark : []
        }
    }



    componentDidMount = async () => {

        this.setState({
            showSpiner: true,
        });

        await this.getGuiaEntrevista();
        await this.getAnswerGuideInterview();

        // Creamos la lista de objetos para preguntas profundas
        var preguntasPro = this.crearListaPreguntasProundas(this.state.deepInterview);
        // Creamos la lista de objetos para preguntas por competencias
        var preguntasCom = this.crearListaPreguntasCompetencias(this.state.competenceInterview);
        // creamos la lista de header de competencias
        var headersCompetencias = await this.getHeaderCompetence(this.state.competenceInterview);
        // verificamos si ya hay respuestas a las preguntas de la entrevista
        preguntasPro = this.addAnswerDeepQuestions(preguntasPro, this.state.answerDeepInterview);
        preguntasCom = this.addAnswerCompetenceQuestions(preguntasCom, this.state.answerCompetenceInterview);
        headersCompetencias = this.addScoreHeader(headersCompetencias, this.state.answerCompetenceInterview);

        // obtenemos el numero de paginas para mostrar en paginacion
        var totalPaginasProfundas = Math.ceil(preguntasPro.length / 2);
        var totalPaginasCompetencias = Math.ceil(preguntasCom.length / 1);

        this.setState({
            todasPreguntasProfundas: preguntasPro,
            paginasPreguntasProfundas: totalPaginasProfundas,
            paginasPreguntasCompetencias: totalPaginasCompetencias,
            todasPreguntasCompetencias: preguntasCom,
            headerCompetenciasList: headersCompetencias,
            showSpiner: false
        });

        setTimeout(() => {
            this.obtenerPreguntasProfundasActuales(1);
            this.obtenerPreguntasCompetenciasActuales(1);

        }, 1000);

    }

    refresTokenGuide = async () => {
        try {
            let authCookie = GetAuth();
            let email = authCookie.user.email;
            let refrest = await axios.post(`${_SERVER_GUIA_ENTREVISTA}/authorization/token?email=${email}`, {});
            if (refrest.status === 200) {
                var auth = cookies.get('auth');
                auth.tokenGuia = refrest.data.token;
                // guardamos la cookie
                SaveCookeSession(auth);
                await this.getGuiaEntrevista();
            }
        } catch (error) {
            message.error("Ocurrió un error al refrescar el token de la guía de entrevista");
        }
    }

    getBenchmark = async () => {
        try {
            var tokenBrive = GetTokenBrive();
            var url = `${_SERVERBRIVE}/api/v1/VacantCandidates/${this.props.objSesion.jobProfileId}/Candidate/${this.props.candidateId}/Action/GetResult`;
            const fetchBencMark = HeaderGet(url, tokenBrive);

            let res = await axiosExcecuteBerchmark(fetchBencMark);
            let array = [];
            if (res.status === 200) {
                res.data.CompetencesAnalyzed.forEach(element => {
                    array.push(element);
                });
                this.setState({
                    datosBerchMark: array,
                    contadorIntentosBerchMark: 0
                })
            }
            else if (res.status === 401) {
                if (this.state.contadorIntentosBerchMark === 0) {
                    var isActualizado = await refreshTokenBrive();
                    if (isActualizado) {
                        this.setState({
                            contadorIntentosBerchMark: 1
                        })
                        await this.getBenchmark();
                    }
                    else{
                        message.error("Ocurrió un error al obtener el berchmark");
                    } 
                }
                
            }
            return array;
        } catch (error) {

            if (error.response !== undefined && error.response.status === 401) {
                if (this.state.contadorIntentosBerchMark === 0) {
                    var isActualizado = await refreshTokenBrive();
                    if (isActualizado) {
                        await this.getBenchmark();
                    }
                    else{
                        message.error("Ocurrió un error al obtener el berchmark");
                        var rectas = [];
                        return rectas;  
                    }
                }
            }
            else{
                message.error("Ocurrió un error al obtener el berchmark");
                var rectas = [];
                return rectas;
            }
        }
    }

    crearListaPreguntasProundas = (listaPreguntas) => {
        try {
            var preguntasPro = [];
            var preguntasProSinOrden = [];
            listaPreguntas.forEach(seccion => {
                if (seccion.questions != null && seccion.questions != undefined && seccion.questions.length > 0) {
                    seccion.questions.forEach(pregunta => {

                        var preguntaObjeto = {
                            idPregunta: pregunta.id,
                            text: pregunta.text,
                            answer: undefined,
                            seccion: "1",
                            orden: pregunta.order,
                            seccionId: seccion.id,
                            seccionName: seccion.name,
                            cantidad: 0,
                        }

                        preguntasProSinOrden.push(preguntaObjeto);
                    });
                }
            });
            preguntasPro = preguntasProSinOrden.sort(((a, b) => a.seccionId - b.seccionId));
            return preguntasPro;
        } catch (error) {
            message.error("Ocurrió un error al crear la lista de preguntas profundas");
            var preguntasProfundas = [];
            return preguntasProfundas;
        }
    }

    addAnswerDeepQuestions = (listaPreguntas, listAnswer) => {
        try {

            listAnswer.forEach(seccion => {
                seccion.questions.forEach(pregunta => {
                    let indicePregunta = listaPreguntas.findIndex(pregunta_ => pregunta_.idPregunta === pregunta.id && pregunta_.seccionId === seccion.id);
                    if (indicePregunta >= 0) {
                        listaPreguntas[indicePregunta].answer = this._getInitialHTML(pregunta.answer);
                    }
                });
            });

            return listaPreguntas;
        } catch (error) {
            return listaPreguntas
        }
    }

    crearListaPreguntasCompetencias = (listaPreguntas) => {
        try {

            var preguntasCompetencias = [];
            var preguntasCompetenciasSinOrdenar = [];
            listaPreguntas.forEach(seccion => {
                // creamos el objeto para las preguntas por competencias
                seccion.questions.forEach(pregunta => {
                    var preguntaObjeto = {
                        preguntaId: pregunta.id,
                        text: pregunta.text,
                        answer: undefined,
                        cantidad: 0,
                        seccionId: seccion.competence.id,
                        seccion: "0",
                        orden: pregunta.order,
                    }
                    preguntasCompetenciasSinOrdenar.push(preguntaObjeto);
                });
            });

            preguntasCompetencias = preguntasCompetenciasSinOrdenar.sort(((a, b) => a.seccionId - b.seccionId));
            return preguntasCompetencias;

        } catch (error) {
            message.error("Ocurrió un error al crear la lista de preguntas por competencias");
            var preguntasCompetencias = [];
            return preguntasCompetencias;
        }
    }

    addAnswerCompetenceQuestions = (listaPreguntas, listAnswer) => {
        try {

            listAnswer.forEach(seccion => {
                seccion.questions.forEach(pregunta => {
                    let indicePregunta = listaPreguntas.findIndex(pregunta_ => pregunta_.preguntaId === pregunta.id && pregunta_.seccionId === seccion.competence.id);
                    if (indicePregunta >= 0) {
                        listaPreguntas[indicePregunta].answer = this._getInitialHTML(pregunta.answer);
                    }
                });
            });

            return listaPreguntas;
        } catch (error) {
            return listaPreguntas
        }
    }

    getHeaderCompetence = async (listaPreguntas) => {
        try {
            var headersCompetencias = [];
            await this.getBenchmark();
            listaPreguntas.forEach(seccion => {
                // obtenemos los headers de las secciones de competencias
                var competenciaName = "";
                var perfilDeseado = 0;
                var talentoEvaluates = 0;
                if (this.state.datosBerchMark.length > 0) {
                    var benchmarkSeleccionado = this.state.datosBerchMark.filter(dato => dato.CompetenceId === seccion.competence.id);
                    if (benchmarkSeleccionado.length > 0) {
                        competenciaName = benchmarkSeleccionado[0].CompetenceName;
                        perfilDeseado = benchmarkSeleccionado[0].VacantScoreRequired;
                        talentoEvaluates = benchmarkSeleccionado[0].CandidateScore;
                    }
                }

                var objetoHeaderCompetencia = {
                    id: seccion.competence.id,
                    name: competenciaName,
                    perfilDeseado: perfilDeseado,
                    talentoEvaluates: talentoEvaluates,
                    score: 0
                }
                headersCompetencias.push(objetoHeaderCompetencia);
            });
            return headersCompetencias;

        } catch (error) {
            message.error("Ocurrió un error al obtener el encabezado de la pregunta por competencia");
            var headerCompetence = [];
            return headerCompetence;
        }
    }

    addScoreHeader = (headerList, answerCompetence) => {
        try {

            answerCompetence.forEach(seccion => {
                let indicePregunta = headerList.findIndex(header_ => header_.id === seccion.competence.id);
                if (indicePregunta >= 0) {
                    headerList[indicePregunta].score = seccion.score;
                }
            });

            return headerList;

        } catch (error) {
            return headerList;
        }
    }

    getGuiaEntrevista = async () => {
        try {
            var tokenGuide = GetTokenGuiaEntrevista();
            var header = {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'Bearer ' + tokenGuide,
            }

            let res = await axios.get(`${_SERVER_GUIA_ENTREVISTA}/jobProfile?jobProfileId=${this.props.objSesion.jobProfileId}`, { headers: header });

            let deepQuestions = [];
            let competenceQuestions = [];

            if (res.data.deepInterview !== null && res.data.deepInterview !== undefined) {
                deepQuestions = res.data.deepInterview.topics;
            }

            if (res.data.competenceInterview !== null && res.data.competenceInterview !== undefined) {
                competenceQuestions = res.data.competenceInterview.competences;
            }

            if (res.status === 200) {
                this.setState({
                    deepInterview: deepQuestions,
                    competenceInterview: competenceQuestions,
                });
            }
        } catch (error) {
            message.error("Ocurrió un error al obtener la guía de entrevista");
            if (error.response.status === 401) {
                await this.refresTokenGuide();
            }
        }
    }

    getAnswerGuideInterview = async () => {
        try {

            var answerCompetenceQuestions = [];
            var answerDeepQuestions = [];
            var tokenGuide = GetTokenGuiaEntrevista();
            var header = {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'Bearer ' + tokenGuide,
            }

            let res = await axios.get(`${_SERVER_GUIA_ENTREVISTA}/interviewer/answer?candidateId=${this.props.candidateId}&interviewId=${this.props.interviewId}`, { headers: header });
            if (res.status === 200) {
                if (res.data.deepInterview !== null) {
                    res.data.deepInterview.forEach(element => {
                        if (element.interviewerId === this.props.objSesion.participantId) {
                            answerDeepQuestions = element.topics;
                        }
                    });
                }
                if (res.data.competenceInterview !== null) {
                    res.data.competenceInterview.forEach(element => {
                        if (element.interviewerId === this.props.objSesion.participantId) {
                            answerCompetenceQuestions = element.competences;
                        }
                    });
                }
            }

            this.setState({
                answerDeepInterview: answerDeepQuestions,
                answerCompetenceInterview: answerCompetenceQuestions,
            });

        } catch (error) {
            message.error("Ocurrió un error al obtener las respuestas de la guía de entrevista");
        }
    }

    _getInitialHTML(answer) {
        try {

            const contentBlock = htmlToDraft(answer);
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            return editorState
        } catch (error) {
            return undefined;
        }
    }

    NextPreguntaProfunda = () => {
        var paginaActual = this.state.paginaPreguntaProfundaActual
        var paginaActualInt = paginaActual === "" ? 0 : parseInt(paginaActual);
        if (paginaActualInt < this.state.paginasPreguntasProfundas) {
            var proximaPagina = paginaActualInt + 1;
            this.setState({
                paginaPreguntaProfundaActual: proximaPagina,
            });
            this.obtenerPreguntasProfundasActuales(proximaPagina);
        }
    }

    PreviaPreguntaProfunda = () => {
        var paginaActual = this.state.paginaPreguntaProfundaActual
        var paginaActualInt = paginaActual === "" ? 0 : parseInt(paginaActual);

        if (paginaActualInt > 1) {
            var anteriorPagina = paginaActualInt - 1;
            this.setState({
                paginaPreguntaProfundaActual: anteriorPagina,
            });
            this.obtenerPreguntasProfundasActuales(anteriorPagina);
        } else {
            this.setState({
                paginaPreguntaProfundaActual: 1,
            });
            this.obtenerPreguntasProfundasActuales(1);
        }
    }

    obtenerPreguntasProfundasActuales = (numPagina) => {
        var preguntasActuales = [];
        var offset = (numPagina - 1) * 2;
        preguntasActuales = this.state.todasPreguntasProfundas.slice(offset, offset + 2);
        this.onHideIndicator();
        this.setState({
            preguntasProfundasActuales: preguntasActuales,
            isChangeDeepQuestion: false
        });
    }

    NextPreguntaCompetencia = () => {
        var paginaActual = this.state.paginaPreguntaCompetenciaActual;
        var paginaActualInt = paginaActual === "" ? 0 : parseInt(paginaActual);
        if (paginaActualInt < this.state.paginasPreguntasCompetencias) {
            var proximaPagina = paginaActualInt + 1;
            this.setState({
                paginaPreguntaCompetenciaActual: proximaPagina,
            });
            this.obtenerPreguntasCompetenciasActuales(proximaPagina);
        }
    }

    PreviaPreguntaCompetencia = () => {
        var paginaActual = this.state.paginaPreguntaCompetenciaActual;
        var paginaActualInt = paginaActual === "" ? 0 : parseInt(paginaActual);

        if (paginaActualInt > 1) {
            var anteriorPagina = paginaActualInt - 1;
            this.setState({
                paginaPreguntaCompetenciaActual: anteriorPagina,
            });
            this.obtenerPreguntasCompetenciasActuales(anteriorPagina);
        }
        else {
            this.setState({
                paginaPreguntaCompetenciaActual: 1
            })
            this.obtenerPreguntasCompetenciasActuales(1);
        }
    }

    changeRating = (newRating) => {
        this.setState({
            headerCompetencia: {
                ...this.state.headerCompetencia,
                score: newRating,
            }
        });

        setTimeout(() => {
            this.props.handleChangeHeaderScore(this.state.headerCompetencia);
        }, 1000);
    }

    obtenerPreguntasCompetenciasActuales = (numPagina) => {
        var headerCompetenciaActual = this.state.headerCompetencia;
        var headerListActual = this.state.headerCompetenciasList;

        var preguntasActuales = [];
        var offset = (numPagina - 1) * 1;
        preguntasActuales = this.state.todasPreguntasCompetencias.slice(offset, offset + 1);

        if (preguntasActuales.length > 0 && headerListActual.length > 0) {
            // verificamos que nuestro header actual sea diferente a la seccion de la pregunta
            if (preguntasActuales[0].seccionId !== headerCompetenciaActual.id) {
                // actualizamos la lista de headers con los valores de score correctos
                headerListActual.forEach(header => {
                    if (headerCompetenciaActual.id === header.id) {
                        header.score = headerCompetenciaActual.score;
                    }
                });
                // actualizamos el header de acuerdo al id de la competencia de la pregunta
                var competencia = headerListActual.filter(header => header.id === preguntasActuales[0].seccionId);
                if (competencia.length > 0) {
                    headerCompetenciaActual = competencia[0];
                }
            }
        }
        this.onHideIndicator();
        this.setState({
            preguntasCompetenciasActuales: preguntasActuales,
            headerCompetencia: headerCompetenciaActual,
            headerCompetenciasList: headerListActual,
            isChangeCompetenceQuestion: false
        });
    }

    VerPreguntas = (tipo) => {
        this.setState({
            tipoPreguntas: tipo,
        });

        this.onHideIndicator();
    }

    actualizarState = (e, seccion) => {
        const esValido = e.target.validity.valid;
        // seccion de preguntas profundas
        if (esValido) {

            var valor = e.target.value;
            if (seccion === 1) {
                var paginaActual = 1;
                var valorAnterior = this.state.paginaPreguntaProfundaActual;
                var totalPaginas = this.state.paginasPreguntasProfundas;

                if (valor <= totalPaginas) {
                    paginaActual = valor;
                } else {
                    paginaActual = valorAnterior;
                }

                this.setState({
                    paginaPreguntaProfundaActual: paginaActual
                });

                if (valor !== "" && valor > 0) {
                    this.obtenerPreguntasProfundasActuales(paginaActual);
                }
            }
            // seccion de preguntas por competencias
            else {
                var paginaActual = 1;
                var valorAnterior = this.state.paginaPreguntaCompetenciaActual;
                var totalPaginas = this.state.paginasPreguntasCompetencias;

                if (valor <= totalPaginas) {
                    paginaActual = valor;
                } else {
                    paginaActual = valorAnterior;
                }

                this.setState({
                    paginaPreguntaCompetenciaActual: paginaActual
                });

                if (valor !== "" && valor > 0) {
                    this.obtenerPreguntasCompetenciasActuales(paginaActual);
                }
            }
        }
    };

    handleTextoEnriquecido = (texto, pregunta) => {


        if (pregunta.seccion === "1") {
            let preguntasProfundas = this.state.todasPreguntasProfundas;
            let indicePregunta = this.state.todasPreguntasProfundas.findIndex(pregunta_ => pregunta_.idPregunta === pregunta.idPregunta && pregunta_.seccionId === pregunta.seccionId);

            preguntasProfundas[indicePregunta].answer = texto;

            var isChange = this.state.isChangeDeepQuestion;
            if (texto.getCurrentContent().getPlainText().length > 0 && !isChange) {
                isChange = true;
            }
            this.setState({
                todasPreguntasProfundas: preguntasProfundas,
                isChangeDeepQuestion: isChange,
            });
            this.props.handleRespuestasProfundas(pregunta);
        } else {
            let preguntasPorCompetencia = this.state.preguntasCompetenciasActuales;
            let indicePreguntaCompetencia = this.state.preguntasCompetenciasActuales.findIndex(pregunta_ => pregunta_.idPregunta === pregunta.idPregunta && pregunta_.seccionId === pregunta.seccionId);
            preguntasPorCompetencia[indicePreguntaCompetencia].answer = texto;
            var isChange = this.state.isChangeCompetenceQuestion;
            if (texto.getCurrentContent().getPlainText().length > 0 && !isChange) {
                isChange = true;
            }
            this.setState({
                preguntasCompetenciasActuales: preguntasPorCompetencia,
                isChangeCompetenceQuestion: isChange,
            });
            this.props.handleRespuestasCompetencias(pregunta, this.state.headerCompetencia);
        }
    }

    onEditorStateChange = (preguntaId, editorState) => {
        this.handleTextoEnriquecido(editorState, preguntaId);
        this.handleTextoEnriquecidocantidad(editorState, preguntaId);
        
    }

    handleChangeNotas = (e) => {
        this.setState({
            notas: e
        })
    }

    handleTextoEnriquecidocantidad = (cantidadLetras, pregunta) => {
        if (pregunta.seccion === "1") {
            let preguntasProfundas = this.state.todasPreguntasProfundas;
            let indicePregunta = this.state.todasPreguntasProfundas.findIndex(pregunta_ => pregunta_.idPregunta === pregunta.idPregunta && pregunta_.seccionId === pregunta.seccionId);
            preguntasProfundas[indicePregunta].cantidad = cantidadLetras.getCurrentContent().getPlainText().length;
            this.setState({
                todasPreguntasProfundas: preguntasProfundas,
            });
        } else {
            let preguntasPorCompetencia = this.state.preguntasCompetenciasActuales;
            let indicePreguntaCompetencia = this.state.preguntasCompetenciasActuales.findIndex(pregunta_ => pregunta_.idPregunta === pregunta.idPregunta && pregunta_.seccionId === pregunta.seccionId);

            preguntasPorCompetencia[indicePreguntaCompetencia].cantidad = cantidadLetras.getCurrentContent().getPlainText().length;
            this.setState({
                preguntasCompetenciasActuales: preguntasPorCompetencia,
            });
        }
    }

    Profundas = () => {
        return (
            <Card className="border-0 card-body-tabs">
                <Card.Body className="pb-0">
                    <ListGroup className="list-group">
                        <ListGroup.Item className="border-0 pl-0 pb-0 pt-2 list-group__tipo-pregunta-seleccionado" >
                            <FontAwesomeIcon icon={faArrowLeft} className="ml-2" style={{ cursor: 'pointer' }} onClick={() => this.VerPreguntas(0)} /> <label>II. Entrevista profunda</label>
                        </ListGroup.Item>
                        <ListGroup.Item className="border-0 pl-0 pb-0 pt-2 pregunta-competencia__text--blue" >
                            <label>Pregunta guía - personalizada</label>
                        </ListGroup.Item>
                    </ListGroup>

                    <Row>
                        <Col sm={12} className="contenedor-preguntas-profunda">
                            <div className="pb-0 pt-0 ">
                                {this.state.preguntasProfundasActuales.map((pregunta, i) => (
                                    <div key={i}>{this.PreguntaProfunda(pregunta)}</div>
                                ))}
                            </div>
                        </Col>
                    </Row>

                </Card.Body>
                <Card.Footer className="border-0 pt-0 paginacion-pregunta">
                    <Col sm={12} >
                        <Button variant="light" onClick={this.PreviaPreguntaProfunda}>
                            <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
                        </Button>
                        <input type="text" pattern="[0-9]{0,2}" value={this.state.paginaPreguntaProfundaActual} className="paginacion-pregunta__input mr-2 mt-5" id="inputPieEP" onChange={e => this.actualizarState(e, 1)}></input>
                        <label>/ {this.state.paginasPreguntasProfundas}</label>
                        <Button variant="light" onClick={this.NextPreguntaProfunda}>
                            <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
                        </Button>
                    </Col>
                </Card.Footer>
            </Card>
        )
    }

    PreguntaProfunda = (pregunta) => {
        return (
            <div className="pregunta-profunda">
                <label>p: {pregunta.text}</label>
                <Row>
                    <Col sm={12}>
                        <TextoEnrriquecido
                            respuesta={(pregunta.answer)}
                            onEditorStateChange={(event) => this.onEditorStateChange(pregunta, event)}
                            onShowIndicator={this.onShowIndicator}
                        />

                    </Col>
                </Row>
            </div>
        );
    }

    Competencias = () => {
        return (
            <Card className="border-0">
                <Card.Body className="pb-0 pt-3 pregunta-competencia">
                    <ListGroup className="list-group">
                        <ListGroup.Item className="border-0 pl-0 pb-0 pt-2 list-group__tipo-pregunta-seleccionado " >
                            <FontAwesomeIcon icon={faArrowLeft} className="ml-2" style={{ cursor: 'pointer' }} onClick={() => this.VerPreguntas(0)} /> <label>I. Entrevista por competencias</label>
                        </ListGroup.Item>
                    </ListGroup>

                    <Row className="mt-1">
                        <Col sm={12}>
                            <label className="pregunta-competencia__header">{this.state.headerCompetencia.name}</label>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <label className="pregunta-competencia__text--blue ml-4">Área de oportunidad</label>
                        </Col>
                    </Row>

                    <Row>
                        <Col >
                            <Card.Body className="text-center pl-0 pr-0 pb-1 pt-1">
                                <Card.Text className="pregunta-competencia__text">Perfil deseado</Card.Text>
                                <Card.Subtitle className="pregunta-competencia__text">{this.state.headerCompetencia.perfilDeseado}</Card.Subtitle>
                            </Card.Body>
                        </Col>
                        <Col >
                            <Card.Body className="text-center pl-0 pr-0 pb-1 pt-1">
                                <Card.Text className="pregunta-competencia__text">Talento Evaluatest</Card.Text>
                                <Card.Subtitle className="pregunta-competencia__text">{this.state.headerCompetencia.talentoEvaluates}</Card.Subtitle>
                            </Card.Body>
                        </Col>
                        <Col >
                            <Card.Body className="text-center pl-0 pr-0 pb-1 pt-1">
                                <Card.Text className="mb-2 pregunta-competencia__text">Calificar</Card.Text>
                                <Card.Subtitle>
                                    <StarRatings
                                        rating={this.state.headerCompetencia.score}
                                        starRatedColor="#F8CB00"
                                        starHoverColor="#F8CB00"
                                        numberOfStars={5}
                                        changeRating={this.changeRating}
                                        starDimension="10px"
                                        starSpacing="3px"
                                        name='rating' />
                                </Card.Subtitle>
                            </Card.Body>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12} className="contenedor-preguntas">
                            <div className="pb-0 pt-0 ">
                                {this.state.preguntasCompetenciasActuales.map((dato) => (
                                    this.PreguntaCompetencia(dato)
                                ))}
                            </div>
                        </Col>
                    </Row>


                </Card.Body>
                <Card.Footer className="border-0 pt-0 pb-0 paginacion-pregunta">
                    <Col sm={12} >
                        <Button variant="light" onClick={this.PreviaPreguntaCompetencia}>
                            <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
                        </Button>
                        <input type="text" pattern="[0-9]{0,2}" value={this.state.paginaPreguntaCompetenciaActual} className="paginacion-pregunta__input mr-2 " id="inputPieEC" onChange={e => this.actualizarState(e, 2)}></input>
                        <label>/ {this.state.paginasPreguntasCompetencias}</label>
                        <Button variant="light" onClick={this.NextPreguntaCompetencia}>
                            <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
                        </Button>
                    </Col>
                </Card.Footer>
            </Card>
        );
    }

    onShowIndicator = () => {
        if (!this.state.saveProgress.loading) {
            this.setState({
                saveProgress: {
                    ...this.state.saveProgress,
                    loading: true,
                    save: false,
                }
            });
            setTimeout(() => {
                this.setState({
                    saveProgress: {
                        ...this.state.saveProgress,
                        loading: false,
                        save: true,
                    }
                });
            }, 2000);
        }
    }
    onHideIndicator = () => {
        this.setState({
            saveProgress: {
                ...this.state.saveProgress,
                save: false,
            }
        });
    }

    PreguntaCompetencia = (pregunta) => {
        return (
            <div className="pregunta-profunda">

                <Row>
                    <Col sm={12}>
                        <label className="pregunta-competencia__text--blue ml-4">Pregunta personalizada</label>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12}>
                        <label> p: {pregunta.text}</label>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12}>
                        <TextoEnrriquecido
                            respuesta={(pregunta.answer)}
                            onEditorStateChange={(event) => this.onEditorStateChange(pregunta, event)}
                            onShowIndicator={this.onShowIndicator}
                        />
                    </Col>
                </Row>
            </div>
        );
    }

    render() {
        return (
            <React.Fragment>
                <NotificationContainer /> 
                <Card className="border-0">
                    <Card.Body className="header-tab">
                        <RowFlex>
                            <ColFlex>
                                <label className="header-tab__title">Guía de entrevista</label>
                            </ColFlex>
                            <ColFlex>
                                <SaveProcess saveProgress={this.state.saveProgress} />
                            </ColFlex>
                        </RowFlex>
                    </Card.Body>
                </Card>

                {this.state.tipoPreguntas === 0 &&
                    <Card className="border-0 card-body-tabs">
                        <Card.Body className="pb-0 ">
                            {(this.state.showSpiner) ? (<div className="col-md-12 text-center mt-2">
                                <Spinner animation="border" variant="primary" /><br />
                                <h6 style={{ fontStyle: "italic" }}>Obteniendo guía de entrevista</h6>
                            </div>) : null}

                            {(this.state.todasPreguntasProfundas.length > 0 || this.state.todasPreguntasCompetencias.length > 0) &&
                                <ListGroup>
                                    {(this.state.todasPreguntasCompetencias.length > 0) &&
                                        <ListGroup.Item className="border-0 pl-0 pb-0 pt-2 tipo-pregunta cursor-puntero" onClick={() => this.VerPreguntas(2)}>
                                            <label className="cursor-puntero">I. Entrevista por competencias</label>
                                        </ListGroup.Item>
                                    }
                                    {(this.state.todasPreguntasProfundas.length > 0) &&
                                        <ListGroup.Item className="border-0 pl-0 pb-0 pt-2 tipo-pregunta cursor-puntero" onClick={() => this.VerPreguntas(1)}>
                                            <label className="cursor-puntero">II. Entrevista profunda</label>
                                        </ListGroup.Item>
                                    }
                                </ListGroup>
                            }
                            {(!this.state.showSpiner && this.state.todasPreguntasProfundas.length === 0 && this.state.todasPreguntasCompetencias.length === 0) &&
                                <h6 style={{ fontStyle: "italic" }}>No se encontraron preguntas para el puesto...</h6>
                            }
                        </Card.Body>
                    </Card>
                }

                {this.state.tipoPreguntas === 1 &&
                    this.Profundas()
                }

                {this.state.tipoPreguntas === 2 &&
                    this.Competencias()
                }

            </React.Fragment>
        )
    }
}

export default Preguntas;