import React, { Component } from 'react';
import { Button, InputGroup, Modal, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faPlusCircle, faTimes, faPlus } from '@fortawesome/free-solid-svg-icons';
import './AgregarEntrevistador.css';
import sinFoto from "../images/sin-foto.jpg";
import logo from '../images/perfil-icon.jpg'
import Select from 'react-select';
import cuidades from '../../componentes/prefijosCuidades.json';
import axios from 'axios';
import { _HEADER, _HEADER_BRIVE, _SERVER, _SERVERBRIVE } from '../../settings';
import { encodeFileToBase64 } from "../../helpers";
import CargarAvatar from '../cargaArchivo/CargarAvatar'

const EMAIL_REGEX = new RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);


class AgregarEntrevistador extends Component {

    constructor(props) {
        super(props);
        this.state = {
            registraEntrevistador: false,
            entrevistadores: [],
            emailEntrevistador: "",
            form: {},
            mostrarAlert: "ocultar",
            showSpinner: false,
            showMsjSinResult: false,
            avatar: sinFoto,
            avatarFormData: null,
            campoRequeridoNombre: "ocultar",
            campoRequeridoCorreo: "ocultar",
            campoRequeridoPuesto: "ocultar",
            campoRequeridoTelefono: "ocultar",
            enabledButton: true,
            campoRequeridoCorreoValor: "",
        };
    }

    componentDidMount() {

    }

    visualizarRegistro = () => {
        this.setState({
            registraEntrevistador: true,
            touched: {
                nombre: false,
                email: false,
                puesto: false,
                codigo: false,
                telefono: false,
            },
            errors: {
                required: {
                    nombre: false,
                    email: false,
                    puesto: false,
                    codigo: false,
                    telefono: false,
                },
                valid: {
                    email: true,
                    nombre: true,
                    puesto: true,
                    codigo: true,
                    telefono: true,
                }
            }
        });
        
        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    };

    cancelar = () => {
        if (this.state.registraEntrevistador) {
            this.setState({
                registraEntrevistador: false
            });
        }
        else {
            this.props.onHide()
        }

        this.setState({
            entrevistadores: [],
            mostrarAlert: "ocultar",
            emailEntrevistador: ""

        })
    }

    aceptar = () => {
        let entrevistadoresElegidos = this.state.entrevistadores.filter(entrevistador => entrevistador.agregado === true);
        entrevistadoresElegidos.forEach(entrevistador => {
            this.props.addEntrevistador(entrevistador)
        });

        this.setState({
            registraEntrevistador: false,
            entrevistadores: [],
            emailEntrevistador: "",
            mostrarAlert: "ocultar",
        });
        this.props.onHide();
    }

    createEntrevistador = async () => {
        await this.setState({
            form: {
                ...this.state.form,
                avatar: this.state.avatar
            }
        })

        let dataUser = {
            username: this.state.form.email,
            Name: this.state.form.nombre,
            Email: this.state.form.email,
            Password: "Aaaa12345$",
            ConfirmPassword: "Aaaa12345$",
            Role: this.state.form.puesto
        }



        if (await this.registerIntervierwer(dataUser)) {

            await this.sendAvatarInterviewer(dataUser.Email);

            let user = await axios.get(`${_SERVER}/api/asp/users/interviewer/email/${dataUser.Email}`, _HEADER);

            this.setState({
                form: {
                    ...this.state.form,
                    id: user.data[0].id
                }
            })

            this.props.addEntrevistador(this.state.form);



            this.setState({
                form: {},
                registraEntrevistador: false,
                avatarFormData: null,
                avatar: sinFoto,
            })
            this.props.onHide();
        } else {

        }


    }

    registerIntervierwer = async (data) => {
        try {
            let response = await axios.post(`${_SERVERBRIVE}/auth/user/register/invited`, data, _HEADER_BRIVE);
            return true;
        } catch (error) {
            return false;
        }

    }

    sendAvatarInterviewer = async (email) => {
        if (this.state.avatarFormData !== null) {
            try {
                let formData = new FormData();
                formData.append("avatar", this.state.avatarFormData)
                let response = await axios.post(`${_SERVERBRIVE}/api/v1/User/${email}/Avatar`, formData, _HEADER_BRIVE)
            } catch (error) {
            }
        }
    }

    loadAvatar = async (file, e) => {
        this.setState({
            avatarFormData: file,
            avatar: await encodeFileToBase64(file)
        })
    }

    handleChange = event => {
        this.setState({
            ...this.state.form,
            [event.target.name]: event.target.value
        })
    }

    handlePhoneCode = (code, event) => {
        this.setState({
            form: {
                ...this.state.form,
                codigo: code
            }
        })


        const errors = {
            required: { ...this.state.errors.required, codigo: false }
        };
        this.setState({
            codigo: code,
            errors: { ...this.state.errors, ...errors }
        });
    }
/*
 obtenemos las propiedades de los formularios para validacion
 */
    handleChangeForm = event => {
        if (event.target.name.includes('nombre')) {
            this.validarNombre(event.target.value);
        } else if (event.target.name.includes('email')) {
            this.validarCorreo(event.target.value);
        } else if (event.target.name.includes('puesto')) {
            this.validarPuesto(event.target.value);
        } else if (event.target.name.includes('telefono')) {
            const esValido = event.target.validity.valid;
            if (esValido) {
                this.validarTelefono(event.target.value);
            }
        }
    }
    //vaidacion del formulario de nombre
    validarNombre = (valor) => {
        if (valor === "" || valor === null || valor === undefined || valor.length === 0) {
            this.setState({
                campoRequeridoNombre: "label-advertencia",
                enabledButton: true,
            })
        } else {
            if (this.state.form.email === undefined || this.state.form.email === "" || this.state.form.puesto === undefined
                || this.state.form.puesto === "" || this.state.form.telefono === undefined || this.state.form.telefono === "") {
                this.setState({
                    enabledButton: true,
                    campoRequeridoNombre: "ocultar",
                })
            } else {
                this.setState({
                    campoRequeridoNombre: "ocultar",
                    enabledButton: false,
                })
            }

        }
        this.setState({
            form: {
                ...this.state.form,
                nombre: valor
            }
        })
    }
      //vaidacion del formulario de correo
    validarCorreo = (valor) => {
        if (valor === "" || valor === null || valor === undefined || valor.length === 0) {
            this.setState({
                campoRequeridoCorreo: "label-advertencia",
                campoRequeridoCorreoValor: "El campo correo electrónico es requerido",
                enabledButton: true,
            })
        } else {
            const emailIsValid = EMAIL_REGEX.test(valor);
            if (emailIsValid) {
                if (this.state.form.nombre === undefined || this.state.form.nombre === "" || this.state.form.puesto === undefined
                    || this.state.form.puesto === "" || this.state.form.telefono === undefined || this.state.form.telefono === "") {
                    this.setState({
                        enabledButton: true,
                        campoRequeridoCorreo: "ocultar",
                    })
                } else {
                    this.setState({
                        campoRequeridoCorreo: "ocultar",
                        enabledButton: false
                    })
                }
            } else {
                this.setState({
                    campoRequeridoCorreoValor: "El correo electrónico no es valido",
                    campoRequeridoCorreo: "label-advertencia",
                    enabledButton: true
                })
            }
        }
        this.setState({
            form: {
                ...this.state.form,
                email: valor
            }
        })
    }
      //vaidacion del formulario de cargo o puesto
    validarPuesto = (valor) => {
        if (valor === "" || valor === null || valor === undefined || valor.length === 0) {
            this.setState({
                campoRequeridoPuesto: "label-advertencia",
                enabledButton: true,
            })
        } else {
            if (this.state.form.nombre === undefined || this.state.form.nombre === "" || this.state.form.email === undefined
                || this.state.form.email === "" || this.state.form.telefono === undefined || this.state.form.telefono === "") {
                this.setState({
                    enabledButton: true,
                    campoRequeridoPuesto: "ocultar",
                })
            } else {
                this.setState({
                    campoRequeridoPuesto: "ocultar",
                    enabledButton: false,
                })
            }
        }
        this.setState({
            form: {
                ...this.state.form,
                puesto: valor
            }
        })
    }
      //vaidacion del formulario de telefono
    validarTelefono = (valor) => {
        if (valor === "" || valor === null || valor === undefined || valor.length === 0) {
            this.setState({
                campoRequeridoTelefono: "label-advertencia",
                enabledButton: true,
            })
        } else {
            if (this.state.form.nombre === undefined || this.state.form.nombre === "" || this.state.form.email === undefined
                || this.state.form.email === "" || this.state.form.puesto === undefined || this.state.form.puesto === "") {
                this.setState({
                    enabledButton: true,
                    campoRequeridoTelefono: "ocultar",
                })
            } else {
                this.setState({
                    campoRequeridoTelefono: "ocultar",
                    enabledButton: false,
                })
            }
        }
        this.setState({
            form: {
                ...this.state.form,
                telefono: valor
            }
        })
    }
//Terminan las validaciones de los formularios--------
    buscar = async () => {
        if (this.state.emailEntrevistador == "" || this.state.emailEntrevistador == null) {
            this.setState({
                mostrarAlert: ""
            });
        } else {
            var data = [];

            try {
                this.setState({
                    showSpinner: true,
                    entrevistadores: [],
                    showMsjSinResult: false,

                });
                let res = await axios.get(_SERVER + "/api/asp/users/interviewer/email/" + this.state.emailEntrevistador, _HEADER);
                var isResult = res.data.length > 0 ? false : true;
                res.data.forEach(entrevistador => {
                    let coincidencias = this.props.listEntrevistadores.filter(entrevistadorList => entrevistadorList.id === entrevistador.id);
                    if (coincidencias.length === 0) {
                        data.push({
                            id: entrevistador.id,
                            nombre: entrevistador.name,
                            avatar: entrevistador.urlAvatar !== null ? entrevistador.urlAvatar : logo,
                            agregado: false
                        })
                    }

                });
                this.setState({
                    showSpinner: false,
                    entrevistadores: data,
                    showMsjSinResult: isResult,
                });
            } catch (error) {
                this.setState({
                    showSpinner: false,
                    showMsjSinResult: false
                });
            }

            this.setState({
                mostrarAlert: "ocultar",
            });
        }
    }

    addEntrevistadorToList = (entrevistador, event) => {
        let entrevistadoresList = this.state.entrevistadores.filter(entrevistadorList => entrevistadorList.id !== entrevistador.id);
        entrevistador.agregado = !entrevistador.agregado;
        entrevistadoresList.push(entrevistador);
        this.setState({
            entrevistadores: entrevistadoresList
        })
    }

    hasError(field) {
        return (this.state.errors.required[field] || !this.state.errors.valid[field]) && this.state.touched[field];
    }

    handleBlur(event) {

        const field = event.target.name;
        this.setState({
            touched: { ...this.state.touched, [field]: true }
        });
        this.validate(event);
    }

    validate(event) {
        const target = event.target;
        const { value, name } = target;

        if (value.length === 0) {
            const errors = {
                required: { ...this.state.errors.required, [name]: true }
            };

            this.setState({
                errors: { ...this.state.errors, ...errors }
            });
            return;
        }

        if (name === 'email') {
            this.validateEmail(value);
        }
    }

    validateEmail(email) {
        const emailIsValid = EMAIL_REGEX.test(this.state.form.email);

    }

    displayError(field) {
        const { required, valid } = this.state.errors;
        const errorMessage = `El campo ${field.replace('email', "correo electrónico")} es `;

        if (required[field]) {
            return `${errorMessage} requerido `;
        }

        if (!valid[field]) {
            return `${errorMessage} invalido`;
        }
    }

    isFormInvalid() {
        const { email, name, errors } = this.state;
        const { required, valid } = errors;
        const isSomeFieldRequired = Object.keys(required).some(error => required[error]);
        const isSomeFieldInvalid = Object.keys(valid).some(error => !valid[error]);

        return isSomeFieldInvalid || isSomeFieldRequired;
    }

    handleKeyDown = async (e) => {
        if (e.key === 'Enter') {
            await this.buscar();
        }
    }

    limpiar = () => {
        this.setState({
            entrevistadores: [],
            emailEntrevistador: "",
            showMsjSinResult: false
        })
    }

    render() {
        const valor = this.state.registraEntrevistador;
        const { show, onHide } = this.props
        const { handleChangeForm } = this;
        const telefono = this.state.form.telefono;
        return (
            <Modal show={show} onHide={this.cancelar} aria-labelledby="contained-modal-title-vcenter" centered   >

                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Agregar entrevistador
                </Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-registro">
                    {valor === false &&
                        <section>
                            <div className="row">
                                <div className="col-sm-12">
                                    <label>Correo electrónico</label>
                                    <InputGroup>
                                        <input
                                            className="form-control"
                                            placeholder="Correo electrónico"
                                            name="emailEntrevistador"
                                            value={this.state.emailEntrevistador || ''}
                                            onChange={this.handleChange}
                                            onKeyDown={this.handleKeyDown}
                                            required
                                        />
                                        <InputGroup.Append>
                                            <Button variant="outline-secondary" onClick={this.buscar}><FontAwesomeIcon icon={faSearch} /></Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                    <div className={this.state.mostrarAlert}>
                                        <label className="color-texto-label-advertencia">campo requerido</label>
                                    </div>
                                </div>
                            </div>

                            {this.state.showSpinner === true &&
                                <div className="spiner-buscando">
                                    <Spinner animation="border" variant="primary" role="status" /><br />
                                    <label>Buscando</label>
                                </div>
                            }


                            <div>
                                <div className="row mt-2">
                                    {(this.state.showMsjSinResult === true || this.state.entrevistadores.length > 0) &&
                                        <div className="col clearfix">
                                            <div className="float-left">
                                                <label>{this.state.entrevistadores.length} Resultados</label>
                                            </div>
                                        </div>
                                    }

                                    {this.state.entrevistadores.length > 0 &&
                                        <div className="col clearfix">
                                            <div className="float-right">
                                                <Button variant="light" className="pt-0" onClick={this.limpiar}>
                                                    <label className="texto-nuevo-entrevistador mb-0">limpiar</label>
                                                </Button>
                                            </div>
                                        </div>
                                    }

                                </div>
                                {this.state.entrevistadores.length > 0 &&
                                    <div className="row seccion-resultados-entrevistadores">
                                        {this.state.entrevistadores.map((dato) => (
                                            <div className="col-sm-12 resultado-entrevistador" key={dato.id}>
                                                <div className="profile__avatar">
                                                    <img src={dato.avatar !== null ? dato.avatar : logo} alt="" />
                                                </div>
                                                <div className="col clearfix">
                                                    <label>{dato.nombre}</label>
                                                    <div className="float-right">
                                                        {dato.agregado === false &&
                                                            <Button variant="light" className="pt-0" onClick={(e) => { this.addEntrevistadorToList(dato, e) }}>
                                                                <label className="texto-nuevo-entrevistador mb-0">Agregar</label>
                                                            </Button>
                                                        }
                                                        {dato.agregado === true &&
                                                            <>
                                                                <Button variant="light" className="pt-0" >
                                                                    <label className="texto-nuevo-entrevistador mb-0">agregado</label>
                                                                </Button>
                                                                <FontAwesomeIcon
                                                                    className="text-danger"
                                                                    variant="outline-danger"
                                                                    icon={faTimes}
                                                                    onClick={(e) => { this.addEntrevistadorToList(dato, e) }} />
                                                            </>

                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                }
                            </div>


                            <div className="row mt-3">
                                <div className="col-sm-12" >
                                    <div className="form-row">
                                        <div className="form-group">
                                            <Button variant="outline-primary" className="redondo" onClick={this.visualizarRegistro}>
                                                <FontAwesomeIcon icon={faPlus} className="" />
                                            </Button>
                                        </div>
                                        <div className="form-group" onClick={this.visualizarRegistro}>
                                            <label className="ml-1 texto-nuevo-entrevistador"> Agregar entrevistador</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    }

                    {valor === true &&

                        <section className="ml-3 mr-3">

                            <div className="row">
                                <div className="col-sm-12 mb-3 text-center">
                                    <CargarAvatar loadFile={this.loadAvatar} src={this.state.avatar} />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label for="exampleInputEmail1">Nombre completo</label>
                                        <input
                                            type="text"
                                            name="nombre"
                                            value={this.state.form.nombre || ''}
                                            onChange={this.handleChangeForm}
                                            className="form-control"
                                            placeholder="Nombre completo" />
                                        <label className={this.state.campoRequeridoNombre}>
                                            El campo nombre es requerido</label>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label for="exampleInputEmail1">Correo electrónico</label>
                                        <input
                                            type="email"
                                            name="email"
                                            value={this.state.form.email || ''}
                                            onChange={this.handleChangeForm}
                                            className="form-control"
                                            placeholder="Ej. usuario@correo.com" />
                                        <label className={this.state.campoRequeridoCorreo}>
                                            {this.state.campoRequeridoCorreoValor}</label>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label for="exampleInputEmail1">Cargo o puesto</label>
                                        <input
                                            type="text"
                                            name="puesto"
                                            value={this.state.form.puesto || ''}
                                            onChange={this.handleChangeForm}
                                            className="form-control"
                                            placeholder="Ej. Director general" />
                                        <label className={this.state.campoRequeridoPuesto}>
                                            El campo cargo o puesto es requerido</label>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12">
                                    <label>Teléfono móvil</label>
                                    <div className="form-row">
                                        <div className="form-group col-md-3">
                                            <Select
                                                options={cuidades}
                                                onChange={opt => this.handlePhoneCode(opt.value)}
                                                placeholder="Código"
                                            />
                                        </div>
                                        <div className="form-group col-md-9">
                                            <input
                                                type="text"
                                                name="telefono"
                                                pattern="[0-9]{0,10}"
                                                value={telefono}
                                                onChange={this.handleChangeForm}
                                                className="form-control"
                                                placeholder="5519123123"

                                            />
                                            <label className={this.state.campoRequeridoTelefono}>
                                                El campo telefono es requerido</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={this.cancelar} >Cancelar</Button>
                    {valor === false &&
                        <Button variant="primary" onClick={this.aceptar}>Aceptar</Button>
                    }
                    {valor === true &&
                        <Button variant="primary" disabled={this.state.enabledButton} onClick={this.createEntrevistador}>Aceptar</Button>
                    }
                </Modal.Footer>
            </Modal>
        );
    }
}

export default AgregarEntrevistador;