import React from 'react';
import PropTypes from 'prop-types';
import "./RowFlex.css";

const ColFlex = ({ children }) => {
  return (
    <div className="col-flex">
      { children }
    </div>
  );
};

ColFlex.propTypes = {
  children: PropTypes.element
};

export default ColFlex;