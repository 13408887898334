import React, { useEffect } from "react";
import { Card, Col, Row, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStop } from '@fortawesome/free-solid-svg-icons'
import GenerarRecta from "./Rectas";
import { _SERVERBRIVE, _APP_BASE_URL_EMPRESA } from "../../../settings";
// ejecutor axios
import axiosExcecute from '../../../lib/peticionApiBerchMark';
// obtenemos los tokens
import { GetTokenBrive } from '../../../lib/cookieSession';
// objetos fetch
import { HeaderGet } from '../../../lib/fecthObject';
import { message } from 'antd';
import Cookies from 'universal-cookie';
// libreria para actualizar token
import { refreshTokenBrive } from '../../../lib/refreshTokenBrive';

const cookies = new Cookies();
var detectrtc = require('detectrtc');

class DatosGenerales extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            CompetenceModelId: null,
            JobLevel: null,
            datos: [],
            profileLibrary: [],
            loading: true,
            VacantAfinity: null,
            urlReporte: undefined,
            Ip: "",
            contadorIntentosBerchMark: 0,
            contadorIntentosJobProfile: 0,
            contadorIntentosProfileLibrary: 0,
            contadorIntentosUrlReport: 0
        }
    }

    componentDidMount = async () => {
        await this.getBenchmark();
        await this.getDataJobProfile();
        await this.getProfileLibrary();
        await this.getUrlReporteAfinidad();
    }

    /**
     * Obtiene la información del perfil de compentencias del candidato
     */
    getBenchmark = async () => {
        try {
            var tokenBrive = GetTokenBrive();
            var url = `${_SERVERBRIVE}/api/v1/VacantCandidates/${this.props.objSesion.jobProfileId}/Candidate/${this.props.candidateId}/Action/GetResult`;
            const fetchBencMark = HeaderGet(url, tokenBrive);

            let res = await axiosExcecute(fetchBencMark);
            if (res.status === 200) {
                let array = [];
                res.data.CompetencesAnalyzed.forEach(element => {
                    array.push(element);
                });
                this.setState({
                    datos: array,
                    JobLevel: res.data.VacantAnalyzed.JobLevel,
                    loading: false,
                    VacantAfinity: res.data.VacantAfinity,
                    contadorIntentosBerchMark: 0
                })
            }
            else if (res.status === 401) {
                if (this.state.contadorIntentosBerchMark === 0) {
                    var isActualizado = await refreshTokenBrive();
                    if (isActualizado) {
                        this.setState({
                            contadorIntentosBerchMark : 1
                        });
                        await this.getBenchmark();
                    }
                    else{
                        message.error("Ocurrió un error al obtener el berchmark");
                        this.setState({
                            loading: false
                        })  
                    }
                }
            }
        } catch (error) {
            if (error.response !== undefined && error.response.status === 401) {
                if (this.state.contadorIntentosBerchMark === 0) {
                    var isActualizado = await refreshTokenBrive();
                    if (isActualizado) {
                        this.setState({
                            contadorIntentosBerchMark : 1
                        });
                        await this.getBenchmark();
                    }
                    else{
                        message.error("Ocurrió un error al obtener el berchmark");
                        this.setState({
                            loading: false
                        })  
                    }
                }
            }
            else{
                message.error("Ocurrió un error al obtener el berchmark");
                this.setState({
                    loading: false
                })
            }
        }
    }
    
    getDataJobProfile = async () => {

        try {
            var tokenBrive = GetTokenBrive();
            var url = `${_SERVERBRIVE}/api/v1/jobProfile/${this.props.objSesion.jobProfileId}/language/es-MX`;
            const fetchDataJobProfile = HeaderGet(url, tokenBrive);

            let res = await axiosExcecute(fetchDataJobProfile);

            if (res.status === 200) {
                this.setState({ 
                    CompetenceModelId: res.data.JobProfile.CompetenceModelId,
                    contadorIntentosJobProfile : 0
                })
            } 
            else if (res.status === 401) {
                if (this.state.contadorIntentosJobProfile === 0) {
                    var isActualizado = await refreshTokenBrive();
                    if (isActualizado) {
                        this.setState({
                            contadorIntentosJobProfile : 1
                        })
                        await this.getDataJobProfile();
                    }
                    else{
                        message.error("Ocurrió un error al obtener el jobProfile");
                    }
                }
            }
            
        } catch (error) {

            if (error.response !== undefined && error.response.status === 401) {
                if (this.state.contadorIntentosJobProfile === 0) {
                    var isActualizado = await refreshTokenBrive();
                    if (isActualizado) {
                        await this.getDataJobProfile();
                    }
                    else{
                        message.error("Ocurrió un error al obtener el jobProfile");
                    }
                }
            }
            else{
                message.error("Ocurrió un error al obtener el jobProfile");
            }
        }
    }

    getProfileLibrary = async () => {
        const { CompetenceModelId, JobLevel } = this.state;
        try {
            var tokenBrive = GetTokenBrive();
            var url = `${_SERVERBRIVE}/api/v1/ProfileLibrary/competences/${CompetenceModelId}/JobLevel/${JobLevel}`;
            const fetchProfileLibrary = HeaderGet(url, tokenBrive);

            let res = await axiosExcecute(fetchProfileLibrary);

            if (res.status === 200) {
                this.setState({ 
                    profileLibrary: res.data,
                    contadorIntentosProfileLibrary: 0
                });
            }
            else if (res.status === 401) {
                if (this.state.contadorIntentosProfileLibrary === 0) {
                   var isActualizado = await refreshTokenBrive();
                    if (isActualizado) {
                        this.setState({
                            contadorIntentosProfileLibrary: 1
                        })
                        await this.getProfileLibrary();
                    }
                    else{
                        message.error("Ocurrió un error al obtener la libreria de un perfil");
                    } 
                }
            }
        } catch (error) {

            if (error.response !== undefined && error.response.status === 401) {
                if (this.state.contadorIntentosProfileLibrary === 0) {
                    var isActualizado = await refreshTokenBrive();
                    if (isActualizado) {
                        await this.getProfileLibrary();
                    }
                    else{
                        message.error("Ocurrió un error al obtener la libreria de un perfil");
                    }
                }
            }
            else{
                message.error("Ocurrió un error al obtener la libreria de un perfil");
            }
        }
    }

    getUrlReporteAfinidad = async () =>{
        try {
            var tokenBrive = GetTokenBrive();
            var url = `${_SERVERBRIVE}/api/v1/jobprofile/candidates/${this.props.candidateId}/report/share/${this.props.objSesion.jobProfileId}/es-MX/language`;
            const fetchReportCandidato = HeaderGet(url, tokenBrive);

            let res = await axiosExcecute(fetchReportCandidato);
            if (res.status === 200) {
                if (res.data.ReportShareUri !== null && res.data.ReportShareUri !== undefined && res.data.ReportShareUri !== "") {
                    var urlReporteShare = _APP_BASE_URL_EMPRESA + "/reporte/compartido/"+res.data.ReportShareUri;

                    this.setState({
                        urlReporte : urlReporteShare,
                        contadorIntentosUrlReport: 0
                    });
                }
            }
            else if (res.status === 401) {
                if (this.state.contadorIntentosUrlReport === 0) {
                    var isActualizado = await refreshTokenBrive();
                    if (isActualizado) {
                        this.setState({
                            contadorIntentosUrlReport: 1
                        });
                        await this.getUrlReporteAfinidad();
                    }
                    else{
                        message.error("Ocurrió un error al obtener la liga del reporte de afinidad");
                    }
                }
            }
        } catch (error) {

            if (error.response !== undefined && error.response.status === 401) {
                if (this.state.contadorIntentosUrlReport === 0) {
                    var isActualizado = await refreshTokenBrive();
                    if (isActualizado) {
                        this.setState({
                            contadorIntentosUrlReport: 1
                        });
                        await this.getUrlReporteAfinidad();
                    }
                    else{
                        message.error("Ocurrió un error al obtener la liga del reporte de afinidad");
                    }
                }
            }
            else{
                message.error("Ocurrió un error al obtener la liga del reporte de afinidad");
            }
        }
    }

    getScoreCompetece = (competences) => {
        return this.state.profileLibrary.filter(pl => pl.Id === competences.CompetenceId)[0];
    }

    openReport = () => {
        try {
            window.open(this.state.urlReporte,"_blank");
        } catch (error) {
            message.error("Ocurrió un error al abrir la liga del reporte de afinidad");
        }
    }

    render() {
        return (
            <div>
                <Card className="border-0">
                    <Card.Body className="header-tab">
                        <label className="header-tab__title">Generales</label>
                    </Card.Body>
                </Card>
                {!this.state.loading ?
                    <Card className="border-0">
                        <Card.Body className="pb-0 pt-3 card-body-tabs">
                            {this.state.datos.length > 0 ?
                                <div >
                                    <Row className="header-berchmark">
                                        <Col sm={12}>
                                            <label className="header-berchmark__afinidad">Afinidad con el puesto:</label> <label className="header-berchmark__porcentaje">{this.state.VacantAfinity}%</label>
                                            
                                            {(this.state.urlReporte !== null && this.state.urlReporte !== undefined) ? <div className="float-right">
                                                <label onClick={() => this.openReport() } className="header-berchmark__porcentaje cursor-puntero">Ver reporte</label>
                                            </div> : null}
                                        </Col>
                                    </Row>
                                    <Row className="identificadores-berchmark">
                                        <Col className="text-right">
                                            <FontAwesomeIcon icon={faStop} className="identificadores-berchmark__icon-perfil" /> <label className="identificadores-berchmark__text">Perfil deseado</label>
                                        </Col>
                                        <Col className="pl-0 pr-0">
                                            <FontAwesomeIcon icon={faStop} className="identificadores-berchmark__icon-evaluates" /> <label className="identificadores-berchmark__text">{this.props.objSesion !== undefined ? this.props.objSesion.nombreCandidato : "Candidato"}</label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        {this.state.profileLibrary.length > 0 &&
                                            < div className="contenedor-preguntas-profunda">
                                                <Col sm={12}>
                                                    <label className="header-berchmark__oportunidad">Áreas de oportunidad</label>
                                                </Col>
                                                {this.state.datos.map((dato, i) => (
                                                    <Col sm={12} key={i} className="mb-5">
                                                        <GenerarRecta valores={dato} profileLibrary={this.getScoreCompetece(dato)} />
                                                    </Col>
                                                ))}
                                            </div>
                                        }
                                    </Row>
                                </div> :
                                <h6 style={{ fontStyle: "italic" }}>No se encontraron datos de afinidad al puesto...</h6>
                            }
                        </Card.Body>
                    </Card> :
                    <div className="text-center">
                        <Spinner animation="border" variant="primary" />
                        <h5>Obteniendo datos</h5>
                    </div>
                }
            </div >
        )
    }

}
export default DatosGenerales;