import React from 'react';
import PropTypes from 'prop-types';
import CloudDoneIcon from "../../../images/Icons/CloudDone";
import { Icon } from 'antd';

import RowFlex from "./RowFlex";

const SaveProcess = ({ saveProgress }) => {
  const { loading, save } = saveProgress;

  if (loading) {
    return (
      <RowFlex>
        <span>
          <Icon type="sync" spin className="save-process-indicator__icon" />
        </span>
        <span>
        <span className="save-process-indicator__text">Guardando cambios</span>
        </span>
      </RowFlex>
    );
  } else if (save) {
    return (
      <RowFlex>
        <span>
          <CloudDoneIcon className="save-process-indicator__icon" />
        </span>
        <span>
          <span className="save-process-indicator__text">Cambios guardados</span>
        </span>
      </RowFlex>
    );
  } else {
    return null;
  }
};

SaveProcess.propTypes = {
  saveProgress: PropTypes.object.isRequired
};

export default SaveProcess;