import React from 'react';

// importamos el router
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// importamos bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.css";


// importamos las vistas
import Login from "./componentes/login/Login";
import AgendarEntrevista from "./componentes/agendarEntrevista/AgendarEntrevista";
import EntrevistasCandidato from "./componentes/entrevistasCandidato/EntrevistasCandidato"
import IniciarVideo from "./componentes/VideoEntrevista/IniciarVideo";
import EntrevistasEntrevistador from "./componentes/entrevistasEntrevistador/EntrevistasEntrevistador";
import Confirmacion from "./componentes/confirmacion/Confirmacion";
import NotFound from "./componentes/fragments/404Page";

function App() {
  
  return (
    <React.Fragment>
      <Router>
        <Switch>
          <Route path="/login/candidato" exact render={props => (<Login isCandidate={true} {...props} />)} ></Route>
          <Route path="/login/interviewer" exact render={props => (<Login isCandidate={false} {...props} />)} ></Route>
          <Route path="/agendar-entrevista/:id/:AssesorId" exact render={props => (<AgendarEntrevista {...props} />)}></Route>
          <Route path="/editar-entrevista/:id/:AssesorId/meetingEdit/:idSheduler/:case" exact render={props => (<AgendarEntrevista {...props} />)}></Route>
          <Route path="/entrevistas/candidate" exact render={props => (<EntrevistasCandidato {...props} />)}></Route>
          <Route path="/entrevistas/interviewer" exact render={props => (<EntrevistasEntrevistador {...props} />)}></Route>
          <Route path="/iniciar/entrevista/candidato/:accessCode" exact render={props => (<IniciarVideo isCandidate={true} {...props} />)}></Route>
          <Route path="/iniciar/entrevista/interviewer/:accessCode" exact render={props => (<IniciarVideo isCandidate={false} {...props} />)}></Route>
          <Route path="/confirmacion" exact render={props => (<Confirmacion {...props} />)}></Route>
          <Route component={NotFound} />
        </Switch>
      </Router>
    </React.Fragment>
  );
}

export default App;
