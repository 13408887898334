
import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import avatar from "../../images/perfil-icon.jpg";
import {  _SERVER } from "../../../settings";
// ejecutor axios
import axiosExcecute from '../../../lib/peticionApi';
// obtenemos los tokens
import { GetTokenVideoEntrevista } from '../../../lib/cookieSession';
// objetos fetch
import { HeaderGet } from '../../../lib/fecthObject';
import { message } from 'antd';
class CabeceraEntrevistador extends React.Component
{
    constructor(props) {
        super(props);
        this.state = {
            scheduleId: Number(this.props.schedule),
            candidate: {
                id : null,
                userId : null,
                email : null,
                name : null,
                urlAvatar : null,
                experience : null,
                minSalary : null,
                maxSalary : null,
                age: null
            },
            experiencia : "Sin experiencia",
            salario: "Desconocido",
            edad : null
        }
    }

    componentDidMount = async () => {
        await this.getInfoCandidate();
    }

    getInfoCandidate = async () =>{
        try {
            var tokenVideo = GetTokenVideoEntrevista();
            var url = `${_SERVER}/api/schedule/${this.props.schedule}/candidate`;
            const fetchDataCandidate = HeaderGet(url, tokenVideo);
            
            let res = await axiosExcecute(fetchDataCandidate);
            
            if (res.status === 200) 
            {
                let edad = null;
                let salario = this.getSalary(res.data.minSalary, res.data.maxSalary);
                let experiencia = "Sin experiencia";

                if(res.data.experience != null && res.data.experience > 0)
                {
                    experiencia = res.data.experience + " años";
                }
                
                if (res.data.age != null && res.data.age > 0) {
                    edad = res.data.age +" años";
                }

                this.setState({
                    candidate : res.data,
                    experiencia : experiencia,
                    salario : salario,
                    edad : edad
                });
            }
            return res.data; 
        } catch (error) {
            message.error("Ocurrió un error al obtener la información del candidato");
        }
    }

    getSalary = (minSalary, maxSalary) =>
    {
        var salario = "Desconocido";
        if (minSalary != null && maxSalary != null) {
            var textSalario = "";
            if (minSalary != null) {
                textSalario = minSalary;
            }
            if (maxSalary != null) {
                if (textSalario === "") {
                    textSalario = maxSalary;
                }
                else
                {
                    textSalario += " - "+maxSalary;
                }
            }

            if (textSalario !== "") {
                salario = textSalario;
            }
        }
        return salario;
    }

    render()
    {
        return(
            <Card className="border-0">
                <Card.Body>
                    <Row >
                        <Col sm={6}>
                            <div className="panel panel-default">
                                <div className="panel-body">
                                    <div className="perfil-avatar">
                                        <img src={this.state.candidate.urlAvatar != null ? this.state.candidate.urlAvatar : avatar} alt=""/>
                                    </div>
                                    <div className="perfil-header">
                                        <label className="perfil-header__name">{this.state.candidate.name}</label><br/>
                                        <label className="perfil-header__age">{this.state.edad}</label><br/>
                                        <label className="perfil-header__email">{this.state.candidate.email}</label>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col sm={3}>
                            <SeccionesHeader titulo="Salario deseado" subtitulo={this.state.salario}/>
                        </Col>
                        <Col sm={3}>
                            <SeccionesHeader titulo="Experiencia" subtitulo={this.state.experiencia}/>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        )
    }
}

function SeccionesHeader(props){
    return(
        <div className="salario text-center">
            <label className="perfil-header__age">{props.titulo}</label><br/>
            <label className="salario__text">{props.subtitulo}</label>
        </div>
    );
}

export default CabeceraEntrevistador;