import axios from 'axios';
import { DeleteCookieSession } from '../lib/cookieSession';
const newAxios = axios.create();

export default async function axiosExecute(...args){

    try {
        const response = await newAxios(...args);
        return response;
    } catch (error) {
        if (error.response.status === 401) {
            DeleteCookieSession();
        }
        else
        {
            throw error.response;
        }
    }

}