import React, { useState, useEffect } from "react";
import { Icon } from 'antd';
import DataTable from "react-data-table-component";
import logo from '../images/perfil-icon.jpg';
import PageInfo from "../pagesInfo/error";
import imgError from "../../images/error.png"
import Header from "../../componentes/fragments/Header";
import { _NUMXPAGE, _SERVER } from '../../settings';
import Loading from "../spinners/spinner";
import 'moment-timezone';
import { OverlayTrigger, Popover, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faCircle } from '@fortawesome/free-solid-svg-icons';
import "react-data-table-component-extensions/dist/index.css";
import "../entrevistasEntrevistador/EntrevistasEntrevistador.css";
// ejecutor axios
import axiosExcecute from '../../lib/peticionApi';
// obtenemos los tokens
import { GetTokenVideoEntrevista, GetAuth } from '../../lib/cookieSession';
// objetos fetch
import { HeaderGet, } from '../../lib/fecthObject';
import { goToEntrevista, GoToLogin } from '../../lib/Redireccion';
// imagenes
import cameraDisable from '../../../src/componentes/images/iconoCameraDisble.svg';
import iconoParticipant from '../../../src/componentes/images/iconoParticipant.svg';
import iconoCamera from '../../../src/componentes/images/iconoCamera.svg';
import Pagination from '@material-ui/lab/Pagination';
import CompatibilityHeadband from '../CompatibilityHeadband';

import RowFlex from "../VideoEntrevista/funciones/RowFlex";
import ColFlex from "../VideoEntrevista/funciones/RowFlex/ColFlex";
import EventBusyIcon from "../../images/Icons/EventBusy";
import { message } from 'antd';
import 'moment/locale/es';
var moment = require('moment');

const AdvancedPaginationTable = (props) => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(_NUMXPAGE);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchKey, setSearchkey] = useState("");
    const [totalPages, setTotalPages] = useState(0);

    const acceptancestatus = {
        AGENDADA: 3,
        EN_CURSO: 6,
        ENVIADO: 9,
        TERMINADA: 7,
        NO_ENVIADO: 10,
        CONFIRMADO: 11,
        POSPUESTO: 12,
        RECHAZADO: 13,
        SIN_RESPUESTA: 14,
        CANCELADA: 8,
    }

    /**
     * Metodo que obtiene los registros de entrevistas por paginación
     * @param {Tipo de dato number que identifica a la pagina} page 
     * @param {Tipo de dato numer ue identifica el numero de registros por pagina} size 
     */
    const fetchUsers = async (page, size = perPage) => {
        try {
            setLoading(true);
            var tokenVideo = GetTokenVideoEntrevista();
            var url = `${_SERVER}/api/Schedule/candidate/page/number/search/${props.candidateId}?numRegistros=${size}&numPagina=${page}&searchKey=${searchKey}`;
            const fetchSchedules = HeaderGet(url, tokenVideo);
            
            let response = await axiosExcecute(fetchSchedules);
            setLoading(false);
            setData(response.data.scheduleDtoList);
            setTotalRows(response.data.numRegister);
            setTotalPages(response.data.totalPages);

        } catch (error) {
            setLoading(false);
            setError(true);
        }
    };

    /**
     * Método que obtiene las entrevistas por paginación cuando se realizo un filtro
     * @param {Tipo de dato number que identifica a la pagina} page 
     * @param {Tipo de dato number que identifica el numero de registros por pagina} size 
     * @returns 
     */
    const fetchFilter = (page, size = perPage) => {
        try {
            setLoading(true);
            var lista = listFilter(searchKey);
            var listaPaginada = [];
            var offset = (page - 1) * size;
            listaPaginada = lista.slice(offset, offset + size);
            var totalPages = Math.ceil(lista.length / size);
            setLoading(false);
            setData(listaPaginada);
            setTotalRows(lista.length);
            setTotalPages(totalPages);
            return;
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    };

    useEffect(() => {
        fetchUsers(1);
    }, []);

    /**
     * Método que realiza la paginación en la tabla ya sea con filtro o sin filtro
     * @param {Evento de paginación} e 
     * @param {Pagina actual seleccionada} page 
     */
     const handlePageChangeE = (e, page) => {
        if (searchKey.trim().length > 0) {
            fetchFilter(page);
            setCurrentPage(page);
        }
        else{
            fetchUsers(page);
            setCurrentPage(page);
        }
    };

    const columns = [
        {
            name: "Puesto",
            selector: "jobProfileName",
            sortable: true,
            cell: row =>    <div data-tag="allowRowEvents" className="table-body">
                                <label className="table-body--blue">{row.jobProfileName}</label>
                            </div>
        },
        {
            name: "Entrevistador",
            selector: "interviewerInformationList",
            sortable: true,
            cell: row =>
                <div className="style-label">
                    {entrevistadores(row.interviewerInformationList)}
                </div>
        },
        {
            name: "Fecha",
            selector: "interviewDate",
            sortable: true,
            cell: row =>
                <div className="table-body">
                    {(row.statusTypeId === acceptancestatus.EN_CURSO) &&
                        <div>
                            {currentInterview(row.startTime, row.endTime) ? <span>
                                <FontAwesomeIcon icon={faCircle} className="icon-size-circle" />
                                <label>Ahora | </label> <label className="table-body--blue table-body--bold enlace-unirse" onClick={() => goToInterview(row.accessCode)} >Unirse</label>
                            </span> : <> {cambiarFormato(row.startTime)} <span><label className="table-body--blue table-body--bold enlace-unirse" onClick={() => goToInterview(row.accessCode)} > | Unirse</label></span></>}
                        </div>
                    }
                    {(row.statusTypeId === acceptancestatus.TERMINADA) &&
                    <div>
                        {cambiarFormato(row.startTime)} <span><label className="table-body--blue table-body--bold enlace-unirse" onClick={() => goToInterview(row.accessCode)} > | Unirse</label></span>
                    </div>   
                    }
                    {(row.statusTypeId === acceptancestatus.AGENDADA || row.statusTypeId === acceptancestatus.CONFIRMADO || row.statusTypeId === acceptancestatus.ENVIADO || row.statusTypeId === acceptancestatus.NO_ENVIADO) && 
                        <div>
                            {currentInterview(row.startTime, row.endTime) ? <span className="seccion-status" >
                                <FontAwesomeIcon icon={faCircle} className="icon-size-circle" />
                                <label>Ahora | </label> <label className="table-body--blue enlace-unirse" onClick={() => goToInterview(row.accessCode)} >Unirse</label>
                            </span> : cambiarFormato(row.startTime)}
                        </div>
                    }
                    {(row.statusTypeId !== acceptancestatus.AGENDADA && row.statusTypeId !== acceptancestatus.EN_CURSO && row.statusTypeId !== acceptancestatus.CONFIRMADO && row.statusTypeId !== acceptancestatus.ENVIADO && row.statusTypeId !== acceptancestatus.NO_ENVIADO && row.statusTypeId !== acceptancestatus.TERMINADA) && 
                        <div>
                            {cambiarFormato(row.startTime)}
                        </div>
                    }
                </div>

        }, 
        {
            name: "Estatus",
            selector: "status",
            sortable: true,
            cell: row =>
                <div data-tag="allowRowEvents" className="table-body">
                    {(row.statusTypeId === acceptancestatus.AGENDADA || row.statusTypeId === acceptancestatus.CONFIRMADO || row.statusTypeId === acceptancestatus.ENVIADO || row.statusTypeId === acceptancestatus.NO_ENVIADO) && 
                        <div>
                            <span className="seccion-status">
                                <img className="seccion-status__imagen" src={iconoParticipant} alt="" />
                                <label className="seccion-status__no-iniciado">No iniciada</label>
                            </span>
                        </div>
                    }
                    {row.statusTypeId === acceptancestatus.EN_CURSO && 
                        <div>
                            {currentInterview(row.startTime, row.endTime) ?<span className="seccion-status">
                                <img className="seccion-status__imagen" src={iconoCamera} alt="" />
                                <label className="seccion-status__iniciado">Iniciada</label>
                            </span> :  <span className="seccion-status" >
                                <img className="seccion-status__imagen" src={cameraDisable} alt="" />
                                <label className="seccion-status__evaluado">Evaluada</label>
                            </span>}
                        </div>
                    }
                    {row.statusTypeId === acceptancestatus.TERMINADA && <span className="seccion-status" >
                        <img className="seccion-status__imagen" src={cameraDisable} alt="" />
                        <label className="seccion-status__evaluado">Evaluada</label>
                    </span>}
                    {(row.statusTypeId === acceptancestatus.CANCELADA || row.statusTypeId === acceptancestatus.RECHAZADO) && <span className="seccion-status">
                        <RowFlex>
                            <ColFlex>
                                <EventBusyIcon className="custom-status__icon custom-status__icon--cancel"/>
                            </ColFlex>
                            <ColFlex>
                                <label className="seccion-status__cancelado custom-status__label">Cancelada</label>
                            </ColFlex>
                        </RowFlex>
                    </span>}
                    {row.statusTypeId === acceptancestatus.POSPUESTO && <span className="seccion-status">
                        <RowFlex>
                            <ColFlex>
                                <Icon type="clock-circle" className="custom-status__icon custom-status__icon--reschedule" />
                            </ColFlex>
                            <ColFlex>
                                <label className="seccion-status__pospuesto custom-status__label">Pospuesta</label>
                            </ColFlex>
                        </RowFlex>
                    </span>}
                    
                </div>
        }
    ]

    /**
     * Método que valida si ya se puede iniciar una entrevista o aun no.
     * @param {Tipo de dato string que identifica a la fecha de inicio de la entrevista} dateInterview 
     * @param {Tipo de dato string que identifica a la fecha de fin de la entrevista} endTime 
     * @returns bool true de que la entrevista se esta llevando en este momento, false de que no se esta llevando en este momento
     */
    const currentInterview = (dateInterview, endTime) => {
        var interviewDate = new Date(dateInterview);
        var endTimeInterview = new Date(endTime);
        interviewDate.setMinutes(interviewDate.getMinutes() - 10);
        endTimeInterview.setMinutes(endTimeInterview.getMinutes() - 10);
        if (new Date() >= interviewDate && new Date() <= endTimeInterview)
            return true;
        return false;
    }


    const entrevistadores = (listEntevistadores) => {
        return (listEntevistadores !== undefined && listEntevistadores.length > 0) ? <div className="table-body">
            <div className="table-body__avatar">
                <img src={listEntevistadores[0] === null ? listEntevistadores[0].urlAvatar : logo} alt="" />
            </div>
            <div >
                <label className="table-body--blue mb-0">
                    {listEntevistadores[0].name}
                    {listEntevistadores.length > 1 ?
                        <OverlayTrigger placement="right" overlay={popover(listEntevistadores)}>
                            <FontAwesomeIcon className="ml-3" icon={faPlusCircle} />
                        </OverlayTrigger> : null
                    }

                </label>

            </div>
        </div> : null
    }


    const popover = (listEntrevistadores) => {
        return <Popover id="popover-basic">
            <Popover.Title as="h3">Entrevistadores</Popover.Title>
            {listEntrevistadores.map((entrevistador, i) => (
                <Popover.Content key={i}>
                    <div className="">
                        <div className="table-body__avatar">
                            <img src={entrevistador === null ? entrevistador.urlAvatar : logo} alt="" />
                        </div>
                        <div className="entrevistador__name"><br />
                            <label>{entrevistador.name}</label>
                        </div>
                    </div>
                </Popover.Content>
            ))}
        </Popover>

    };

    /**
     * Método que cambia el formato de la fecha de la entrevista
     * @param {Tipo de dato string que identifica a la fecha de la entrevista} fecha 
     * @returns tipo de dato string con la fecha con formato
     */
    const cambiarFormato = (fecha) => {
        var interviewDate = new Date(fecha);
        var currentDate = new Date();

        let newFecha = "";

        var interviewDateAux = new Date(fecha);
        currentDate.setHours(0,0,0,0);
        interviewDateAux.setHours(0,0,0,0);

        if (currentDate.getTime() === interviewDateAux.getTime()) {
            let ffecha = moment(interviewDate).format('h:mm a');
            newFecha = "Hoy "+ffecha;
        }
        else
        {
            moment.locale('es');
            let month = moment(interviewDate).format('MMMM');
            let ffecha = moment(interviewDate).format('D, YYYY - h:mm a');
            newFecha = capitalizarPrimeraLetra(month) +" "+ffecha;
        }
        
        return newFecha
    }

    /**
     * Método que conviert un texto con la primer letra en mayuscula y las demas en minusculas
     * @param {Tipo de dato string que identifica al texto que se quiere cambiar} str 
     * @returns tipo de dato string
     */
    const capitalizarPrimeraLetra = (str) => {
        return str[0].toUpperCase() + str.slice(1);
    }

    /**
     * Método que realiza el filtro en el datatable
     * @param {evento de input de busqueda dataTable} event 
     * @returns 
     */
    const handleFilter = (event) => {
        let value = event.target.value;
        if (value === "") {
            fetchUsers(1);
            setSearchkey("");
            setCurrentPage(1);
            return
        }
        
        var lista = listFilter(value);
        var listaPaginada = [];
        listaPaginada = lista.slice(0, perPage);
        var totalPages = Math.ceil(lista.length / perPage);
        setData(listaPaginada);
        setSearchkey(value);
        setTotalRows(lista.length);
        setCurrentPage(1);
        setTotalPages(totalPages);
    }

    /**
     * Método que filtra la lista de entrevistas 
     * @param {Tipo de dato string que identifica a la palabra a buscar} search 
     * @returns Lista de objetos filrada
     */
    const listFilter = (search) =>{
        try {
            var searchUpper = search.trim().toUpperCase();
            return props.allEntrevistas.filter(entrevista => 
            checkVale(entrevista.jobProfileName, searchUpper)
            || checkVale(entrevista.statusSchedule, searchUpper)
            || filterInterViewer(entrevista.interviewerInformationList, searchUpper) > 0
            || checkFecha(entrevista.startTime, entrevista.endTime, searchUpper));
        } catch (error) {
            var listavacia = [];
            return listavacia;
        }
    }

    /**
     * Método que filtra la lista de entrevistadores de una agenda.
     * @param {lista de objetos tipo entrevistadores} listInterViewer 
     * @param {Tipo de dato string que idnetifica al valor de la bsuqueda} value 
     * @returns number con el tamano de la lista filtrada
     */
    const filterInterViewer = (listInterViewer, value) => {
        try {
            let interViewers = listInterViewer.filter(interView => checkVale(interView.name, value));
            return interViewers.length;
        } catch (error) {
            return 0
        }
    }

    /**
     * Método que valida y verifica si una cadena contiene la palabra que se esta buscando
     * @param {Tipo de dato string que identifica al campo a filtrar} valor 
     * @param {Tipo de dato string que identifica la palabra a buscar} searchKey 
     * @returns true de si contiene esa palabra, false de no contiene esa palabra
     */
    const checkVale = (valor, searchKey) => {
        try {
            if (valor !== null && valor !== undefined) {
                return valor.toUpperCase().includes(searchKey);
            }
            else{
                return false;
            }
        } catch (error) {
            return false;
        }
    }

    /**
     * Método que filtra la fecha de la entrevista con la palabra de busqueda 
     * @param {Tipo de dato string que identifica a la fecha de inicio de la entrevista} interviewDate 
     * @param {Tipo de dato string que identifica a la fecha de fin de la entrevista} endDate 
     * @param {Tipo de dato string que identifica a la palabra a buscar} searchKey 
     * @returns true la fecha contiene la palabra a buscar, false la fecha no contiene la palabra a buscar
     */
    const checkFecha = (interviewDate, endDate, searchKey) => {
        try {
            if(interviewDate !== undefined)
            {
                var date = cambiarFormato(interviewDate);

                var interviewStartDate = new Date(interviewDate);
                moment.locale('es');
                let month = moment(interviewStartDate).format('MMMM');
                let ffecha = moment(interviewStartDate).format('D, YYYY - h:mm a');
                let newFecha = capitalizarPrimeraLetra(month) +" "+ffecha;

                var currentDate = new Date();
                var interviewDateAux = new Date(interviewDate);
                currentDate.setHours(0,0,0,0);
                interviewDateAux.setHours(0,0,0,0);
                var hoy = "";
                if (currentDate.getTime() === interviewDateAux.getTime()) {
                    if (currentInterview(interviewDate, endDate)) {
                        hoy = "Ahora | Unirse";
                    }
                }

                if(date.toUpperCase().includes(searchKey)){
                    return true;
                }
                else if (newFecha.toUpperCase().includes(searchKey)) {
                    return true;   
                }
                else{
                    if (hoy !== "") {
                        if(hoy.toUpperCase().includes(searchKey)){
                            return true;
                        }
                    }
                    return false;
                }
            }
            else{
                return false;
            }
        } catch (error) {
            message.error("Ocurrió un error al validar las fechas de una entrevista");
            return false;
        }
    }

    /**
     * Método que redirecciona a la vista para iniciar una entrevista
     * @param {Tipo de dato string que identifica al accesCode de la agenda} accessCode 
     */
    const goToInterview = (accessCode) =>{
        var url =  `/iniciar/entrevista/candidato/${accessCode}`;
        window.location.href= url;
    }

    return (
        <React.Fragment>
            <DataTable
                title="Mis entrevistas"
                columns={columns}
                data={data}
                noDataComponent={<h6>No hay registros para mostrar</h6>}
                subHeader={true}
                responsive={true}
                subHeaderComponent={
                    <div className="width-search">
                        <input className="form-control form-control-sm" name="search" onChange={handleFilter} placeholder="Buscar en mis entrevistas" />
                    </div>
                }
            />

            <Row className="pb-3 pt-3">
                <Col md={12} className="container-paginacion">
                    {(totalPages > 0) &&
                        <Pagination count={totalPages} page={currentPage} onChange={handlePageChangeE} variant="outlined" shape="rounded" />
                    }
                </Col>
            </Row>

            { loading ? <Loading msj="Cargando tus entrevistas..." /> : null}
            { error ? <PageInfo imgError={imgError} msj="Ocurrió un error en el servidor, intentelo de nuevo" onHide={() => setError(false)} /> : null}
        </React.Fragment>
    );
};


class EntrevistasCandidato extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            existingPage: 1,
            entrevistas: [],
            participantId: undefined,
            compatibility: true,
        }
    }

    /**
     * Componente que se ejecuta al inicar la pagina
     * Realiza la validación del tipo de usuario y que este logueado
     */
    componentDidMount = async () => {
        var auth = GetAuth();
        if (auth !== undefined) {

            await this.setState({
                participantId : auth.user.participantId,
            });

            if (!auth.user.isCandidate) {
                goToEntrevista();
            }
            else{
                this.getAllEntrevistas();
            }
        }
        else{
            GoToLogin();
        }
        
    }

    /**
     * Método que obtiene todas las entrevistas en segundo plano
     */
    getAllEntrevistas = async () => {
        try {
            var tokenVideo = GetTokenVideoEntrevista();
            var url = `${_SERVER}/api/Schedule/candidate/${this.state.participantId}`;
            const fetchInterviewers = HeaderGet(url, tokenVideo);
            
            let interviewers = await axiosExcecute(fetchInterviewers);
            if (interviewers.status === 200 && interviewers.data !== null) {
                this.setState({
                    entrevistas: interviewers.data
                });
            }
        } catch (error) {
            message.error("Ocurrió un error al obtener las agendas de un entrevistador");
        }
        
    }


    toggleCompatibility = (compatibility) => {
        this.setState({ compatibility })
    }

    render() {
        return (
            <React.Fragment>
                <Header />
                <div className="mb-3">
                    {
                        this.state.participantId !== undefined
                        &&
                        <div className={`dashboard-interviews ${this.state.compatibility ? 'dashboard-interviews--compatible' : 'dashboard-interviews--not-compatible'}`}>
                            <CompatibilityHeadband toggleCompatibility={this.toggleCompatibility} />
                            <section className="contenedor-entrevistas container pb-3">
                                <AdvancedPaginationTable allEntrevistas={this.state.entrevistas} candidateId={this.state.participantId} />
                            </section>
                        </div>
                    }
                    
                </div>
            </React.Fragment>
        )
    }

}

export default EntrevistasCandidato;