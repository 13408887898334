import React from 'react';
import { Icon } from 'antd';

const Edit = props => {
  const EditSvg = () => (
    <svg id="edit_black_24dp" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16">
      <path id="Trazado_33531" data-name="Trazado 33531" d="M0,0H16V16H0Z" fill="none"/>
      <path id="Trazado_33532" data-name="Trazado 33532" d="M3,12.5V15H5.5l7.373-7.373-2.5-2.5ZM14.807,5.693a.664.664,0,0,0,0-.94l-1.56-1.56a.664.664,0,0,0-.94,0l-1.22,1.22,2.5,2.5,1.22-1.22Z" transform="translate(-1 -0.999)" fill="currentColor"/>
    </svg>

  );

  return (<Icon component={EditSvg} {...props} />);
};

export default Edit;