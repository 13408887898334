import React, { Component } from 'react';
import logo from '../images/logo.svg'
import './Login.css'
import ReCAPTCHA from "react-google-recaptcha";
import { InputGroup, FormControl, Spinner, Button, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { _SERVER, _SERVERBRIVE, _SERVER_GUIA_ENTREVISTA, _CAPTCHAT_KEY, _SERVER_BRIVE_CANDIDATE, _IS_PRODUCCION } from "../../settings";

import axiosExcecute from '../../lib/peticionApi';
import { SaveCookeSession, GetAuth } from '../../lib/cookieSession';
import { goToEntrevista } from '../../lib/Redireccion';
import { urlHashString } from '../../lib/Helper';
import Cookies from 'universal-cookie';
 
const cookies = new Cookies();
// import detectrtc from 'detectrtc';
var detectrtc = require('detectrtc');
class Login extends Component {

    constructor(props) {
        super(props);
    }
    state = {
        mostrarContra: "password",
        iconoContra: faEye,
        valueCaptcha: true,
        form: {
            isCandidate: this.props.isCandidate,
            email: null,
            accessCode: null,
            password: null
        },
        participantId: null,
        mostrarAlert: "ocultar",
        hablitar: true,
        campoRequerido: "ocultar",
        campoRequeridoCorreo: "ocultar",
        campoRequeridoContra: "ocultar",
        mostrarAlertSwitch: "ocultar",
        textoAlertSwitch: "",
        loading: false,
        Ip: undefined,
        loginUrlHash: "",
        userEmail: "",
        isCandidateUser: false,
        emailExist: false
    }

    /**
     * Componente que se ejecuta al iniciar la pagina
     * Verifica si ya se cuenta con una sesión, de contar lo redirige a la vista de mis entrevistas
     */
    componentDidMount = async () => {
        const encryptUserHash = urlHashString('eh');
        const encryptCandidateHash = urlHashString('ceh');

        if (encryptCandidateHash != null && encryptCandidateHash.length > 0) {
            await this.setState({
                loginUrlHash: encryptCandidateHash,
                isCandidateUser: true,
            });
            await this.encryptedCandidateUrlHashLogin();
        }
        else if (encryptUserHash != null && encryptUserHash.length > 0) {
            await this.setState({
                loginUrlHash: encryptUserHash,
                isCandidateUser: false,
            });
            await this.encryptedUserUrlHashLogin();
        }
        var auth = GetAuth();
        if (auth !== undefined) {
            goToEntrevista();
        }

        this.getIp();

    }

    getIp = async () => {
        try {
            const response = await axiosExcecute({
                url: `https://api.ipify.org`,
                method: 'get',
            });
            this.setState({ Ip: response.data });
        } catch (error) {
            this.setState({ Ip: '' });
        }
    }

    /**
     * Método que permite mostrar u ocultar la contraseña
     */
    verContra = () => {
        if (this.state.mostrarContra === "password") {
            this.setState({
                mostrarContra: "text",
                iconoContra: faEyeSlash
            });
        } else {
            this.setState({
                mostrarContra: "password",
                iconoContra: faEye
            });
        }
    };

    /**
     * Método que valida que los campos para realizar la autenticación este llenos
     */
    IrEntrevistas = async () => {
        this.setState({
            mostrarAlertSwitch: "ocultar",
            textoAlertSwitch: ""
        });

        if (this.state.form.email === null || this.state.form.email === "" || this.state.form.email.trim().length === 0) {
            this.setState({
                mostrarAlert: "",
                campoRequerido: "ocultar",
                campoRequeridoCorreo: "label-advertencia"
            });
            if (this.state.form.password === null || this.state.form.password === "" || this.state.form.password.trim().length === 0) {
                this.setState({
                    campoRequeridoContra: "label-advertencia"
                });
            } else {
                this.setState({
                    campoRequeridoContra: "ocultar",
                });
            }
        } else if (this.state.form.password === null || this.state.form.password === "" || this.state.form.password.trim().length === 0) {
            this.setState({
                mostrarAlert: "",
                campoRequerido: "ocultar",
                campoRequeridoCorreo: "ocultar",
                campoRequeridoContra: "label-advertencia"
            });
        } else {
            await this.login();
            this.setState({
                mostrarAlert: "ocultar",
                campoRequerido: "ocultar",
                campoRequeridoCorreo: "ocultar",
                campoRequeridoContra: "ocultar"
            });
        }
    }

    /**
     * Método que obtiene los valores de los inputs del formulario 
     * @param {Evento de inputs del formulario} event 
     */
    handleChange = event => {
        this.setState({
            form: {
                ...this.state.form,
                [event.target.name]: event.target.value
            }
        })
    }

    /**
     * Método que identifica cuando se selecciono el captchat
     * @param {Tipo de dato string que identifica el valor del captchat} value 
     */
    valorCaptcha = (value) => {
        this.setState({
            valueCaptcha: value,
            hablitar: false
        });

    }

    /**
     * Método que cierra la advertencia en caso de ocurrir un error
     */
    cerrarAlertSwitch = () => {
        this.setState({
            mostrarAlertSwitch: "ocultar"
        });
    }

    /**
     * Método que realiza la autentitcación identificando el tipo de peticion que se realizara
     * Candidato o entrevistador
     */
    login = async () => {
        try {
            this.setState({
                loading: true,
            });

            if (!this.state.form.isCandidate) {
                var tokenVideo = await this.getTokenVideoEntrevista();
                var tokenBrive = await this.getTokenBrive();
                var tokenGuide = await this.getTokenGuiaEntrevista();
                // verificamos si los token son validos 
                if (tokenVideo !== null) {
                    if (tokenBrive !== null && tokenGuide !== null) {
                        this.setState({
                            participantId: tokenVideo.participantId
                        });
                        // si los token son valodos etramos a la entrevista
                        var dataSession = {
                            tokenBrive: tokenBrive.access_token,
                            tokenVE: tokenVideo.token,
                            tokenGuia: tokenGuide.token,
                            refres_toke: tokenBrive.refresh_token,
                            user: {
                                isCandidate: this.state.form.isCandidate,
                                email: this.state.form.email,
                                name: tokenVideo.participantName,
                                participantId: tokenVideo.participantId,
                                password: this.state.form.password
                            }
                        }
                        if(cookies.get('cerrarSession') != null && cookies.get('cerrarSession') != undefined){
                            cookies.remove("cerrarSession", { path: '/' });
                        }

                        // creamos la cookie de session
                        SaveCookeSession(dataSession);
                        goToEntrevista();
                    }
                    else {
                        var mensaje = this.state.textoAlertSwitch;
                        if (mensaje === "") {
                            this.setState({
                                mostrarAlertSwitch: "",
                                textoAlertSwitch: "Ocurrió un error verifique su conexión de red"
                            });
                        }
                    }
                }
            }
            else {
                var tokenVideo = await this.getTokenVideoEntrevista();
                var responseCA = await this.getTokenBriveCandidate();
                if (tokenVideo !== null) {
                    if (responseCA !== null) {
                        this.setState({
                            participantId: tokenVideo.participantId
                        });
                        // si los token son valodos etramos a la entrevista
                        var dataSession = {
                            tokenVE: tokenVideo.token,
                            user: {
                                isCandidate: this.state.form.isCandidate,
                                email: this.state.form.email,
                                name: tokenVideo.participantName,
                                participantId: tokenVideo.participantId,
                                password: this.state.form.password
                            }
                        }

                        if(cookies.get('cerrarSession') != null && cookies.get('cerrarSession') != undefined){
                            cookies.remove("cerrarSession", { path: '/' });
                        }
                        // creamos la cookie de session
                        SaveCookeSession(dataSession);
                        goToEntrevista();
                    }
                    else {
                        var mensaje = this.state.textoAlertSwitch;
                        if (mensaje === "") {
                            this.setState({
                                mostrarAlertSwitch: "",
                                textoAlertSwitch: "Ocurrió un error verifique su conexión de red"
                            });
                        }
                    }

                }
            }
            this.setState({
                loading: false,
            });
        } catch (error) {
            this.setState({
                loading: false,
            });
            return null;
        }
    }

    encryptedCandidateUrlHashLogin = async () => {
        let candidateHashtokenVideo = await this.getInterviewTokenFromHash();
        if (candidateHashtokenVideo !== null) {
            this.setState({
                participantId: candidateHashtokenVideo.participantId,
            });
            let candidateHashDataSession = {
                tokenVE: candidateHashtokenVideo.token,
                user: {
                    isCandidate: true,
                    email: candidateHashtokenVideo.userEmail,
                    name: candidateHashtokenVideo.participantName,
                    participantId: candidateHashtokenVideo.participantId,
                },
            };

            SaveCookeSession(candidateHashDataSession);
            goToEntrevista();
        } else {
            let mensaje = this.state.textoAlertSwitch;
            if (mensaje !== "") {
                this.setState({
                    mostrarAlertSwitch: "",
                    textoAlertSwitch: "Ocurrió un error verifique su conexión de red",
                });
            }
        }
    };

    encryptedUserUrlHashLogin = async () => {
        let briveHashToken = await this.getBriveTokenFromHash();
        if (briveHashToken !== null) {
            let interviewHashToken = await this.getInterviewTokenFromHash();
            if (interviewHashToken !== null) {
                await this.setState({
                    userEmail: interviewHashToken.userEmail,
                });
                await this.setState({
                    participantId: interviewHashToken.participantId,
                });
                let interviewGuideHashToken = await this.getInterviewGuideTokenFromHash();
                if (interviewHashToken !== null) {
                    let dataSessionHash = {
                        tokenBrive: briveHashToken.access_token,
                        tokenVE: interviewHashToken.token,
                        tokenGuia: interviewGuideHashToken.token,
                        refres_toke: briveHashToken.refresh_token,
                        user: {
                            isCandidate: false,
                            email: interviewHashToken.userEmail,
                            name: interviewHashToken.participantName,
                            participantId: interviewHashToken.participantId,
                        },
                    };
                    SaveCookeSession(dataSessionHash);
                    goToEntrevista();
                }
            }
            else {
                let mensaje = this.state.textoAlertSwitch;
                if (mensaje !== "") {
                    this.setState({
                        mostrarAlertSwitch: "",
                        textoAlertSwitch: "Ocurrió un error verifique su conexión de red"
                    });
                }
            }
        }
    };

    getInterviewTokenFromHash = async () => {
        let fetchVideoEntrevistaHash = {
            method: "POST",
            url: `${_SERVER}/api/Autenticacion/url/hash/token?isCandidate=${this.state.isCandidateUser}`,
            headers: {
                "Content-Type": "application/json",
            },
            data: JSON.stringify(this.state.loginUrlHash),
        };
        try {
            const responseVideohash = await axiosExcecute(fetchVideoEntrevistaHash);
            return responseVideohash.data;
        } catch (error) {
            return null;
        }
    };

    getBriveTokenFromHash = async () => {
        let fetchBriveHash = {
            method: "POST",
            url: `${_SERVERBRIVE}/auth/interview/user/token`,
            headers: {
                "Content-Type": "application/json",
            },
            data: JSON.stringify(this.state.loginUrlHash),
        };
        try {
            const responseBrivehash = await axiosExcecute(fetchBriveHash);
            return responseBrivehash.data;
        } catch (error) {
            return null;
        }
    };

    getInterviewGuideTokenFromHash = async () => {
        let fetchBriveGuideHash = {
            method: "post",
            url: `${_SERVER_GUIA_ENTREVISTA}/authorization/token?email=${this.state.userEmail}`,
            data: {},
        };

        let responseGuideHash = await axiosExcecute(fetchBriveGuideHash);
        return responseGuideHash.data;
    };

    /**
     * Método que obtiene el token del backend de video entrevista
     * este token es para candidatos y entrevistadores
     * @returns objeto con token de video entrevista
     */
    getTokenVideoEntrevista = async () => {
        try {
            let infoUserDto = {
                "email": this.state.form.email,
                "isCandidate": this.state.form.isCandidate
            }

            const fetchVideoEntrevista = {
                method: 'post',
                url: `${_SERVER}/api/Autenticacion/token`,
                data: infoUserDto
            };
            // ejecutamos la peticion al back de videonetrevista
            var response = await axiosExcecute(fetchVideoEntrevista);
            if (response.status == 200) {
                this.setState({
                    mostrarAlertSwitch: "ocultar",
                    textoAlertSwitch: "",
                    emailExist: true,
                })
                return response.data;
            } else if (response.status == 204) {
                this.setState({
                    mostrarAlertSwitch: "",
                    textoAlertSwitch: "El correo electrónico y la contraseña son incorrectos",
                    emailExist: false,
                });
                return null;
            } else {
                this.setState({
                    mostrarAlertSwitch: "",
                    textoAlertSwitch: "Ocurrió un error verifique su conexión de red",
                    emailExist: false,
                });
                return null;
            }

        } catch (error) {
            this.setState({
                mostrarAlertSwitch: "",
                textoAlertSwitch: "Ocurrió un error verifique su conexión de red",
                emailExist: false,
            });
            return null;
        }
    }

    /**
     * Método que obtiene el token de un entrevistador
     * @returns Objeto con token de Brive
     */
    getTokenBrive = async () => {
        try {

            let userbrive = {
                "OperatingSystem": detectrtc.osName,
                "WebBrowser": detectrtc.browser.name,
                "PublicIP": this.state.Ip,
                "Email": this.state.form.email,
                "Password": this.state.form.password,
                "recaptchaToken": ""
            }


            const fetchBrive = {
                method: 'post',
                url: `${_SERVERBRIVE}/auth/login`,
                data: userbrive
            };

            var response = await axiosExcecute(fetchBrive);
            return response.data;
        } catch (error) {

            if (error.status !== undefined && error.status === 406) {
                this.setState({
                    mostrarAlertSwitch: "",
                    textoAlertSwitch: "La contraseña es incorrecta"
                });
            }
            else if (error.status !== undefined && error.status === 404) {
                this.setState({
                    mostrarAlertSwitch: "",
                    textoAlertSwitch: "El correo electrónico es incorrecto"
                });
            }
            else {
                if (this.state.textoAlertSwitch == "") {
                    this.setState({
                        mostrarAlertSwitch: "",
                        textoAlertSwitch: "Ocurrió un error verifique su conexión de red"
                    });
                }
            }
            return null;
        }
    }

    /**
     * Método que obtiene el token del candidato
     * @returns Objeto con token de candidato
     */
    getTokenBriveCandidate = async () => {
        try {
            const fetchBrive = {
                method: 'post',
                url: `${_SERVER_BRIVE_CANDIDATE}/auth/login`,
                data: `grant_type=password&username=${this.state.form.email}&password=${this.state.form.password}`,
            };
            var response = await axiosExcecute(fetchBrive);
            return response.data
        } catch (error) {
            if (error.status !== undefined && error.status === 406) {
                this.setState({
                    mostrarAlertSwitch: "",
                    textoAlertSwitch: "La contraseña es incorrecta"
                });
            } else if (error.status !== undefined && error.status === 404) {
                this.setState({
                    mostrarAlertSwitch: "",
                    textoAlertSwitch: "El correo electrónico es incorrecto"
                });
            }
            else {
                if (this.state.textoAlertSwitch == "") {
                    var mensajeAlert = "";
                    if (this.state.emailExist) {
                        mensajeAlert = "La contraseña es incorrecta";
                    }
                    else {
                        mensajeAlert = "Ocurrió un error verifique su conexión de red";
                    }
                    this.setState({
                        mostrarAlertSwitch: "",
                        textoAlertSwitch: mensajeAlert
                    });
                }

            }
            return null;
        }
    }

    /**
     * Método qe obtiene el token de la guia de entrevistas
     * Este token solo es para el entrevistador
     * @returns Objeto con token de guia de entrevista
     */
    getTokenGuiaEntrevista = async () => {
        try {
            const fetchBriveGuide = {
                method: 'post',
                url: `${_SERVER_GUIA_ENTREVISTA}/authorization/token?email=${this.state.form.email}`,
                data: {}
            };

            var response = await axiosExcecute(fetchBriveGuide);
            return response.data;
        } catch (error) {
            return null;
        }
    }


    render() {

        return (
            <section className="py-5 text-center container">
                <img className="pt-2 size-logo" src={logo} alt="" />
                <h1 className="text-brive pt-3">Entrevistas</h1>
                <div className={this.state.mostrarAlertSwitch}>
                    <Alert variant="danger" className="estiloAlert m-0" onClose={() => this.cerrarAlertSwitch()} dismissible>
                        <p>{this.state.textoAlertSwitch}</p>
                    </Alert>
                </div>
                <container className="row py-lg-4">
                    <section className="col-lg-4 col-md-4 mx-auto">
                        <div className="card text-left">
                            <div className="card-body">
                                <form>
                                    <div className="mb-3">
                                        <label for="correoInput" className="form-label">Correo electrónico</label>
                                        <input type="email" className="form-control" name="email" value={this.state.form.Email} onChange={this.handleChange} required />
                                        <label className={this.state.campoRequeridoCorreo}>El campo Correo electrónico es requerido</label>

                                    </div>
                                    <div className="mb-4">
                                        <label for="contraseñaInput" className="form-label">Contraseña</label>
                                        <InputGroup className="font-large-chat">
                                            <FormControl
                                                type={this.state.mostrarContra}
                                                className="form-control"
                                                name="password"
                                                value={this.state.form.Contra}
                                                onChange={this.handleChange}
                                                required
                                            />
                                            <InputGroup.Append>

                                                <Button variant="light" className="estilo-boton-contra" onClick={() => this.verContra()}>
                                                    <FontAwesomeIcon icon={this.state.iconoContra} className="estilo-icono-contra mr-2" />
                                                </Button>
                                            </InputGroup.Append>
                                        </InputGroup>
                                        <label className={this.state.campoRequeridoContra}>El campo contraseña es requerido</label>

                                    </div>

                                    {(_IS_PRODUCCION) ? <div className="row contenedor-captcha">
                                        <ReCAPTCHA sitekey={_CAPTCHAT_KEY} name="captcha" onChange={this.valorCaptcha} />
                                    </div> : null}

                                    {(this.state.loading) ?
                                        <Button variant="primary" disabled className="btn-block">
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            {' '}Iniciando...
                                        </Button>
                                        : <button className="btn btn-block btn-primary" type="button" onClick={() => this.IrEntrevistas()} disabled={!_IS_PRODUCCION ? false : this.state.hablitar} >
                                            Entrar
                                        </button>
                                    }
                                </form>
                            </div>
                        </div>
                    </section>
                </container>
            </section>
        )
    }
}
export default Login;