import { Modal } from "react-bootstrap";
import React, { Component } from "react";
import "./pagesInfo.css";

class Loading extends Component {


    constructor(props) {
        super(props)
        this.state = {
            show: true,
        }
    }

    close = () => {
        this.setState({
            show: false
        })
        this.props.onHide();
    }


    render() {
        return (
            <Modal
                centered
                size="sm"
                show={this.state.show}
                onHide={() => this.close()}
                aria-labelledby="example-modal-sizes-title-sm"
            >

                <Modal.Body className="text-center">
                    <div className="conteinerLoad">
                        <img src={this.props.imgError} width="120" alt="" />
                    </div>
                    <h6 className="mt-2">{this.props.msj}</h6>
                </Modal.Body>
            </Modal>
        )
    }

}


export default Loading;