import { Modal } from "react-bootstrap";
import React, { Component } from "react";
import "./spinner.css";
import logoEvaluates from '../../images/logo-c-symbol.svg'
class Loading extends Component {


    constructor(props) {
        super(props)
        this.state = {
            show: true,
        }
    }

    close = () => {
        this.setState({
            show: false
        })
    }


    render() {
        return (
            <Modal
                centered
                size="sm"
                show={this.state.show}
                onHide={() => this.close()}
                aria-labelledby="example-modal-sizes-title-sm"
            >

                <Modal.Body className="text-center">
                    <div className="conteinerLoad mb-2">
                        <div className="logo-container">
                            <div className="logo-wrapper">
                                <img
                                    className="rotate mb-2"
                                    src={logoEvaluates}
                                    alt="Evaluatest"
                                />
                            </div>
                            <style jsx>{`
                                .logo-wrapper {
                                    display: flex;
                                    height: 100%;
                                    align-items: center;
                                    justify-content: center;
                                }
                                .logo-wrapper img {
                                    width: 120px;
                                }
                                .rotate {
                                    animation: flip 2s ease infinite;
                                }
                                @keyframes flip{
                                    0%{
                                    transform: perspective(120px) rotateY(180deg);
                                    }
                                    50%{
                                    transform: perspective(120px) rotateY(0deg);
                                    }
                                    100%{
                                    transform: perspective(120px) rotateY(-180deg);
                                    }
                                }
                                `}
                            </style>
                        </div>
                    </div>
                    <h6 >{this.props.msj}</h6>
                </Modal.Body>
            </Modal>
        )
    }

}


export default Loading;