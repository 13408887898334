import React from "react";
import { Card, Col, Row, Button, Spinner } from "react-bootstrap";
import logo from '../../images/perfil-icon.jpg'
import { _SERVER, _TOKEN } from "../../../settings";
import ModalCompartirArchivo from "../funciones/ModalCompartirArchivo";
import { HubConnectionBuilder } from '@microsoft/signalr';

import shared from "../../images/shared.svg";
import dowloand from "../../images/download.svg";
// ejecutor axios
import axiosExcecute from '../../../lib/peticionApi';
// obtenemos los tokens
import { GetTokenVideoEntrevista } from '../../../lib/cookieSession';
// objetos fetch
import { HeaderGet, HeaderGetFile } from '../../../lib/fecthObject';
import { saveAs } from 'file-saver';
import { message } from 'antd';

class Archivos extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            objSesion: props.objSesion,
            scheduleId: props.schedule,
            documentCandidate: [],
            documentInterviewer: [],
            showModalCompartir: false,
            url: null,
            nombreAsunto: props.objSesion.subject,
            showSpinnerInterviewe: false,
            showSpinnerCandidato: false
        }
    }

    componentDidMount = async () => {
        await this.getScheduleDocumentByCandidate();
        await this.getScheduleDocumentByInterviewer();

        this.createConnectionSocket();
    }

    createConnectionSocket = () => {
        const { objSesion } = this.state;
        const options = {
            accessTokenFactory: () => {
                return _TOKEN;
            }
        };
        const newConnection = new HubConnectionBuilder()
            .withUrl(`${_SERVER}/hubs/chat`, options)
            .withAutomaticReconnect()
            .build();

        newConnection.start().then(result => {
            newConnection.on(`file${objSesion.accessCode}`, message => {
                if (message.isCandidate) {
                    this.setState({
                        documentCandidate: [...this.state.documentCandidate, message]
                    })
                } else {
                    this.setState({
                        documentInterviewer: [...this.state.documentInterviewer, message]
                    })
                }
                // setMensajes(m => [...m, message]);
            });
        }).catch(e => {
            message.error("Ocurrió un error al crear la conexión para el chat");
        });
    }

    getScheduleDocumentByCandidate = async () => {
        try {
            var tokenVideo = GetTokenVideoEntrevista();
            var url = `${_SERVER}/api/schedule/document/schedule/${this.state.scheduleId}/candidate/true`;
            const fetchDocumentCandidate = HeaderGet(url, tokenVideo);

            let res = await axiosExcecute(fetchDocumentCandidate);
            
            if (res.status === 200) {
                this.setState({
                    documentCandidate: res.data
                });
            }
        } catch (error) {
            message.error("Ocurrió un error al obtener los documentos del candidato");
        }
    }

    getScheduleDocumentByInterviewer = async () => {
        try {
            var tokenVideo = GetTokenVideoEntrevista();
            var url = `${_SERVER}/api/schedule/document/schedule/${this.state.scheduleId}/candidate/false`;
            const fetchDocumentInterview = HeaderGet(url, tokenVideo);

            let res = await axiosExcecute(fetchDocumentInterview);
            
            if (res.status === 200) {
                this.setState({
                    documentInterviewer: res.data
                });
            }
        } catch (error) {
            message.error("Ocurrió un error al obtener los documentos del entrevistador");
        }
    }

    downloadFile = async (documentId, documentName) => {
        try {
            var tokenVideo = GetTokenVideoEntrevista();
            var url = `${_SERVER}/api/schedule/document/downloader/${documentId}`;
            const fetchDocumentInterview = HeaderGetFile(url, tokenVideo);

            let res = await axiosExcecute(fetchDocumentInterview);
            
            if (res.status === 200) {
                const blob = new Blob([res.data]);
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', documentName);
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(url);
            }
            return res.status;
        } catch (error) {
            message.error("Ocurrió un error al descargar un documentos");
            return null;
        }
    }


    downloadFileBlob = async (documentId, documentName) => {
        try {
            var tokenVideo = GetTokenVideoEntrevista();
            var url = `${_SERVER}/api/schedule/document/downloader/${documentId}`;
            const fetchDocumentInterview = HeaderGetFile(url, tokenVideo);

            let res = await axiosExcecute(fetchDocumentInterview);
            
            if (res.status === 200) {

                var archivoFile = {
                    blob : new Blob([res.data]),
                    nombreFile: documentName
                }
    
                return archivoFile;
            }
            return null;
        } catch (error) {
            message.error("Ocurrió un error al descargar los documentos");
            return null;
        }
    }

    downloadAllFiles = async (isFileCandidate) => {
        try {
            if (isFileCandidate) {
                for (let index = 0; index < this.state.documentCandidate.length; index++) {
                    await this.downloadFile(this.state.documentCandidate[index].id, this.state.documentCandidate[index].documentDocumentName);
                }
            }
            else {
                for (let index = 0; index < this.state.documentInterviewer.length; index++) {
                    await this.downloadFile(this.state.documentInterviewer[index].id, this.state.documentInterviewer[index].documentDocumentName);
                }
            }
        } catch (error) {
            message.error("Ocurrió un error al descargar todos los archivos");
        }
    }

    generarZip = async (isFileCandidate) => {
        try {
            var Zip = require('jszip')();
            if (isFileCandidate) {

                this.setState({
                    showSpinnerCandidato: true
                });

                for (let index = 0; index < this.state.documentCandidate.length; index++) {

                    var file = await this.downloadFileBlob(this.state.documentCandidate[index].id, this.state.documentCandidate[index].documentDocumentName);
                    
                    if (file != null) {
                        Zip.file(file.nombreFile, file.blob, {base64: true});
                    }

                }
            }
            else {

                this.setState({
                    showSpinnerInterviewe: true
                });

                for (let index = 0; index < this.state.documentInterviewer.length; index++) {
                  var file = await this.downloadFileBlob(this.state.documentInterviewer[index].id, this.state.documentInterviewer[index].documentDocumentName);
                    if (file != null) {
                        Zip.file(file.nombreFile, file.blob, {base64: true});
                    }
                }
            }
            var nombreZip = "Entrevista-"+this.state.nombreAsunto.replace(/[/ ]/g, '-')+".zip";
            Zip.generateAsync({type:"blob"}).then(function(content) {
                // see FileSaver.js
                saveAs(content, nombreZip);
            });

            this.setState({
                showSpinnerInterviewe: false,
                showSpinnerCandidato: false
            });

        } catch (error) {
            message.error("Ocurrió un error al generar en archivo ZIP");
            this.setState({
                showSpinnerInterviewe: false,
                showSpinnerCandidato: false
            });
        }
    }

    showModalCompartir = (shotUrl) => {
        this.setState({
            showModalCompartir: true,
            url: shotUrl
        });
    }

    render() {
        return (
            <div>
                <Card className="border-0">
                    <Card.Body className="header-tab">
                        <label className="header-tab__title">Archivos</label>
                    </Card.Body>
                </Card>
                <div className="card-body-tabs">

                {! this.state.objSesion.isCandidate && 
                    <Card className="border-0">
                        <Card.Body className="pt-3 pr-0 ">
                            <Row className="header-document">
                                <Col sm={12} >
                                    <label className="header-document__titulo">Archivos compartidos conmigo</label>
                                    {(this.state.documentInterviewer.length > 0) &&
                                        <div className="header-document float-right">
                                            {(!this.state.showSpinnerInterviewe) ?<label className="header-document__accion cursor-puntero" onClick={() => this.generarZip(false)}>Descargar Todo</label> :

                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                variant="primary"
                                                />
                                            }
                                        </div>
                                    }
                                </Col>
                            </Row>

                            <div className="seccion-documentos-compartidos mt-2">
                                {this.state.documentInterviewer.map((dato, i) => (
                                    <Col sm={12} key={i} className="documento-compartido">
                                        <div className="documento-compartido__avatar">
                                            <img src={dato.avatarParticipant != null ? dato.avatarParticipant : logo} alt="" />
                                        </div>
                                        <div className="col clearfix pr-0 pl-2">
                                            <label className="documento-compartido__nombre">{dato.documentDocumentName}</label>
                                            <div className="float-right">
                                                <Button variant="outline-primary" className="documento-compartido__bton-descarga float-left mr-1" onClick={() => this.showModalCompartir(dato.documentShortRoute)}>
                                                    <img src={shared} alt="" />
                                                </Button>

                                                <Button variant="outline-primary" className="documento-compartido__bton-descarga float-left" onClick={() => this.downloadFile(dato.id, dato.documentDocumentName)} >
                                                    <img src={dowloand} alt="" />
                                                </Button>
                                            </div>
                                        </div>
                                    </Col>
                                ))}
                            </div>
                        </Card.Body>
                    </Card>
                }

                <Card className="border-0">
                    <Card.Body className="pt-3 pr-1">
                        <Row className="header-document">
                            <Col sm={12}>
                                {(this.state.objSesion.isCandidate) ? <label className="header-document__titulo">Archivos compartidos por mí</label> : <label className="header-document__titulo">Archivos del candidato</label> }
                                
                                {(this.state.documentCandidate.length > 0) &&
                                        <div className="header-document float-right">
                                            {(!this.state.showSpinnerCandidato) ? <label className="header-document__accion cursor-puntero" onClick={() => this.generarZip(true)}>Descargar Todo</label> : 

                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                variant="primary"
                                            />
                                            }
                                        </div>
                                    }
                            </Col>
                            
                        </Row>

                        <div className={(this.state.objSesion.isCandidate) ? "seccion-documentos-compartidos-candidato mt-2" : "seccion-documentos-compartidos mt-2"}>

                            {this.state.documentCandidate.map((dato, i) => (
                                <Col sm={12} key={i} className="documento-compartido">
                                    <div className="documento-compartido__avatar">
                                        <img src={dato.avatarParticipant != null ? dato.avatarParticipant : logo} alt="" />
                                    </div>
                                    <div className="col clearfix pr-0 pl-2">
                                        <label className="documento-compartido__nombre">{dato.documentDocumentName}</label>
                                        <div className="float-right">
                                            <Button variant="outline-primary" className="documento-compartido__bton-descarga float-left mr-1" onClick={() => this.showModalCompartir(dato.documentShortRoute)}>
                                                <img src={shared} alt="" />
                                            </Button>

                                            <Button variant="outline-primary" className="documento-compartido__bton-descarga float-left" onClick={() => this.downloadFile(dato.id, dato.documentDocumentName)} >
                                                <img src={dowloand} alt="" />
                                            </Button>
                                        </div>
                                    </div>
                                </Col>
                            ))}

                        </div>

                    </Card.Body>
                </Card>
                </div>
                <ModalCompartirArchivo
                    show={this.state.showModalCompartir}
                    onHide={() => this.setState({ showModalCompartir: false })}
                    url={this.state.url} />
            </div>
        );
    }
}

export default Archivos;