import React from 'react';
import { Icon } from 'antd';

const CloudDoneSVG = () => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor">
    <path
      d="M 19.35 10.04 C 18.67 6.59 15.64 4 12 4 C 9.11 4 6.6 5.64 5.35 8.04 C 2.34 8.36 0 10.91 0 14 c 0 3.31 2.69 6 6 6 h 13 c 2.76 0 5 -2.24 5 -5 c 0 -2.64 -2.05 -4.78 -4.65 -4.96 Z M 10 17 l -3.5 -3.5 l 1.41 -1.41 L 10 14.17 L 15.18 9 l 1.41 1.41 L 10 17 Z"
    />
  </svg>
);

const CloudDone = props => <Icon component={CloudDoneSVG} {...props} />;

export default CloudDone;