import Cookies from 'universal-cookie';
import { GetAuth } from './cookieSession';
const cookies = new Cookies();

export function GoToLogin(){
    var url = "/login/interviewer";
    var auth = GetAuth();
    if (auth !== undefined) {
        if (auth.user.isCandidate) {
         url = `/login/candidate`;
        }
        else{
         url = `/login/interviewer`;
        }
    }
    window.location.href= url;
}

export function goToEntrevista(){
    var url = "";
    var auth = cookies.get('auth');
    if (auth !== undefined) {
        if (auth.user.isCandidate) {
            url = `/entrevistas/candidate`;
        }
        else{
            url = `/entrevistas/interviewer`;
        }
    }
    else{
        url = "/login/interviewer";
    }
    
    window.location.href= url;
}