import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import { useRanger } from "react-ranger";
import { Col, Row } from "react-bootstrap";

export const Track = styled("div")`
  display: inline-block;
  height: 5px;
  width: 90%;
  margin: 0 5%;
`;

export const Tick = styled("div")`
  :before {
    content: "";
    position: absolute;
    left: 0;
    background: #0C7BEA;
    height: 5px;
    width: 5px;
    transform: translate(-50%, 0.7rem);
    border-radius: 100%;
  }
`;

export const TickLabel = styled("div")`
  position: absolute;
  font-size: 0.6rem;
  color: rgba(0, 0, 0, 0.5);
  top: 100%;
  transform: translate(-50%, 1.2rem);
  white-space: nowrap;
`;

function GenerarRecta(props) {
    var perfilDeseado = props.valores.VacantScoreRequired;
    var evaluatesNueve = props.valores.CandidateScore;
    var inicioRango = props.profileLibrary.MinScore;
    var finRango = props.profileLibrary.MaxScore;
    if (inicioRango > finRango) {
        let inicioRangovalidar = inicioRango;
        let finRangovalidar = finRango;
        inicioRango = finRangovalidar;
        finRango = inicioRangovalidar;
    }
    var colorRecta = (inicioRango <= perfilDeseado && perfilDeseado <= finRango) ? "#52C41A" : "#00000029";
    var colorRecta2 = (evaluatesNueve <= finRango && evaluatesNueve >= inicioRango) ? "#52C41A" : "#00000029";

    const Segment = styled("div")`
        background: ${props =>
            props.value === evaluatesNueve
                ? colorRecta2
                : props.value === perfilDeseado
                    ? colorRecta
                    : props.value === finRango
                        ? "#52C41A"
                        : "#00000029"};
            height: 100%;
    `;
    // pinta los triangulos de los resultados
    const Handle = styled("div")`
        width: 0; 
        height: 0; 
        border-top: 0.5rem solid transparent;
        border-bottom: 0.5rem solid transparent;
        border-left: ${props =>
            props.value === perfilDeseado
                ? "0.9rem solid #0C7BEA"
                : props.value === evaluatesNueve
                    ? "0.9rem solid #A07EF8"
                    : props.value === inicioRango
                        ? "0.9rem solid transparent"
                        : props.value === finRango
                            ? "0.9rem solid transparent"
                            : "0.9rem solid transparent"};
        display: flex;
        margin-bottom:20px;
        align-items: center;
        justify-content: center;

        font-size: 0.7rem;
        white-space: nowrap;
        color: white;
        font-weight: ${props => (props.active ? "bold" : "normal")};
        transform: ${props =>
            props.active ? "translateY(-100%) scale(1.3)" : "translateY(0) scale(0.9)"};
        transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);

        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
    `;

    const [values, setValues] = React.useState([perfilDeseado, evaluatesNueve, 0.9, 3]);

    const { getTrackProps, ticks, segments, handles } = useRanger({
        min: 0,
        max: 4,
        stepSize: 1,
        values,
        ticks: [0, 1, 2, 3, 4]
    });

    return (
        <div className="competence">
            <Row>
                <Col sm={12}>
                    <label className="competence__name">{props.valores.CompetenceName}</label>
                </Col>
            </Row>
            <Row>
                <Col sm={3}>
                    <label className="competence__perfil">{perfilDeseado}</label>
                </Col>
                <Col sm={3} className="text-center">
                    <label className="competence__evaluates">{evaluatesNueve}</label>
                </Col>
                <Col sm={6}>
                    <p className="competence__title-rango">Rango de los evaluados<br /> en el Mercado Laboral</p>
                    <label className="competence__text-rango">{inicioRango} - {finRango}</label>
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <Track {...getTrackProps()}>
                        {ticks.map(({ value, getTickProps }) => (
                            <Tick {...getTickProps()}>
                                <TickLabel>{value}</TickLabel>
                            </Tick>
                        ))}
                        {segments.map(({ value, getSegmentProps }, i) => (
                            <Segment {...getSegmentProps()} index={i} value={value} />
                        ))}
                        {handles.map(({ value, active, getHandleProps }) => (
                            <label
                                {...getHandleProps({
                                    style: {
                                        appearance: "none",
                                        border: "none",
                                        background: "transparent",
                                        outline: "none"
                                    }
                                })}
                            >
                                <Handle active={active} value={value} ></Handle>
                            </label>
                        ))}
                    </Track>
                </Col>
            </Row>

        </div>
    )
}

export default GenerarRecta;