import React from "react";
import "./IniciarVideo.css";
import Header from "../../componentes/fragments/Header";
import EncabezadoEntrevistador from "./funciones/EncabezadoEntrevistador";
import ContenidoEstoyListo from "./funciones/ContenidoEstoyListo";
import EsperaParticipantes from "./funciones/EsperaParticipantes";
import FinEntrevista from "./funciones/ContenidoFinEntrevista";
import Archivos from "./funciones/Archivos";
import Notas from "./funciones/Notas";
import Preguntas from "./funciones/Preguntas";
import DatosGenerales from "./funciones/DatosGenerales";
import BrowserSopoert from "./funciones/NoBrowser";
import PageValidacion from "./funciones/PageValidacion";
import Chat from "./funciones/Chat";
import axios from 'axios';
import { _SERVER, _SERVER_GUIA_ENTREVISTA, _MIN_ESPERA_PARTICIPANTE, _MAX_MORE_MINUTES_INTERVIEW } from "../../settings";
import VideoParticipante from "../videoOpenVidu/videoParticipante";
// modales
import CalificarEntrevista from "../modalFinEntrevista/CalificarEntrevista";
import CierreEntrevista from "../modalFinEntrevista/CierreEntrevista";
import PageError from "../pagesInfo/error";
import { Card, Col, Row, Tab, Tabs, Container } from "react-bootstrap";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from "draftjs-to-html";
import { stateFromHTML } from 'draft-js-import-html';
import htmlToDraft from 'html-to-draftjs';

// imagenes
import imagenExito from "../images/confirmar.png";
import imagenError from "../images/rechazado.png";

import imgGrafica from "../images/grafica.svg";
import imgChat from "../images/chat24.svg";
import imgNota from "../images/note.svg";
import imgList from "../images/list24.svg";
import imgFolder from "../images/folder.svg";
import iconoAgregarParticipant from '../images/person_add.svg';
// ejecutor axios
import axiosExcecute from '../../lib/peticionApi';
// obtenemos los tokens
import { GetTokenBrive, GetTokenVideoEntrevista, GetTokenGuiaEntrevista, SaveCookeSession, GetAuth } from '../../lib/cookieSession';
import { GoToLogin, goToEntrevista } from '../../lib/Redireccion';
// objetos fetch
import { HeaderGet, HeaderPost, HeaderPut } from '../../lib/fecthObject';
// creamos el manejador de cookies
import Cookies from 'universal-cookie';
import { message } from 'antd';

const cookies = new Cookies();
const { detect } = require('detect-browser');
const browser = detect();
class IniciarVideo extends React.Component {

    constructor(props) {
        super(props);
    }

    state = {
        accessCode: this.props.match.params.accessCode,
        scheduleId: 0,
        interviewId: 0,
        participantId: null,
        isCandidate: this.props.isCandidate,
        estatusEntrevista: 0,
        estatusParticipante: 0,
        mostrarModalEntrevistador: false,
        mostrarModalCandidato: false,
        mostrarSeccionComponentes: !this.props.isCandidate,
        estilocontenedor: !this.props.isCandidate ? "contenedor-video " : "contenedor-inicio ",
        objSesion: {
            participantId: null,
            accessCode: this.props.match.params.accessCode,
            isCandidate: this.props.isCandidate,
            nombreParticipant: "",
            scheduleId: null,
            urlAvatar: null,
            jobProfileId: null,
            jobProfileName: null,
            subject: "",
            nombreCandidato: "",
            email: ""
        },
        nombreParticipant: "",
        form: {
            Notas: "",
            PreguntasProfundasEntrevistador: [],
            PreguntasCompetencias: [],
        },
        conclusionEntrevistador:
        {
            id: 0,
            interviewId: 0,
            interviewerId: null,
            scoreCompatibility: 0,
            advantageHiring: null,
            riskHiring: null,
            conclusion: null
        },
        conclusionCandidate: {
            id: 0,
            interviewId: 0,
            conclusion: null,
            score: 0
        },
        nota: undefined,
        idIntervalo: null,
        dataCandidate: { name: "" },
        listInterviewer: [],
        listParticipants: [],
        startTime: "",
        endTime: "",
        moreTime: false,
        idIntervaloMoreTime: null,
        habilitarModalCandidato: false,
        habilitarModalEntrevistador: false,
        errorConclusion: false,
        exitoConclusion: false,
        preguntasProfundas: [],
        preguntasCompetencias: [],
        HeaderCompetenciasList: [],
        headerDeepList: [],
        noteInterviewer: {
            id: 0,
            interviewerId: null,
            interviewId: 0,
            note: null
        },
        habilitarNota: false,
        isInterviewInProcess: false,
        seccionTab: "archivos",
        browserSoport: true,
        validacionScheduleParticipant: 0,
        validacionParticipantMessage: "",
        habilitarVistaValidacion: false,
        IsSavedNote: false,
        notaSaveProcess: false,
        showPreguntas: true,
    };

    componentDidMount = async () => {

        if(cookies.get('cerrarSession') != null && cookies.get('cerrarSession') != undefined){
            cookies.remove("cerrarSession", { path: '/' });
        }

        window.addEventListener("beforeunload", (evento) => {

            var isCerrarSesion = cookies.get("cerrarSession");
            if (true && (isCerrarSesion === null || isCerrarSesion === undefined) && this.state.isInterviewInProcess) {
                evento.preventDefault();
                evento.returnValue = "";
                return "";
            }
            else{
                this.colgarLlamada();
            }
        });
        
        // verificamos el tipo de navegador
        if (browser.name === "edge-chromium") {
            await this.setState({
                browserSoport: false,
                mostrarSeccionComponentes: false
            });
        }
        else {
            await this.setState({
                browserSoport: true,
            });
        }

        var auth = GetAuth();
        if (auth !== undefined) {

            var { participantId, email } = auth.user;
            await this.setState({
                objSesion: {
                    ...this.state.objSesion,
                    participantId: participantId,
                    email
                },
                participantId: participantId
            });
            // validamos el estatus de la camara web
            var validationWebCam = await this.validateUseCamera();
            if (validationWebCam === "") {
                // validamos el tiempo, estatus de la entrevista y el participante
                await this.validarParticipantAndSchedule();
                if (this.state.validacionScheduleParticipant === 4 || this.state.validacionScheduleParticipant === 3) {
                    await this.getScheduleByAccessCode();
                    // obtenemos todos los candidatos para mostrar antes de iniciar la sesion
                    await this.getDataCandidate();
                    await this.getInfoInterviewer();
                    await this.getInterviewByScheduleId();
                    await this.getInformationParticipant();

                    if (this.state.isCandidate) {
                        await this.getConclusionCandidate();
                    }
                    else {
                        await this.getConclusionInterviewer();
                        await this.getNoteInterviewer();
                    }
                }
            }
            else {
                await this.setState({
                    validacionParticipantMessage: validationWebCam,
                    habilitarVistaValidacion: true,
                });
            }

        }
        else {
            GoToLogin();
        }
    }



    /**
     * Método que valida el estatus de la camara, si la pagina tiene permisos para usar la camara y el microfono,
     * si la camara esta en uso, o si el dispositivo cuenta con camara web o microfono
     * @returns string vacio si todo esta bien, mensaje en caso de error 
     */
    validateUseCamera = async () => {
        return new Promise((resolve, reject) => {
            var response = "";
            try {
                // if (window.location.protoco === 'https:') {
                navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;
                if (navigator.getUserMedia) {
                    navigator.getUserMedia({ audio: true, video: true }, function (stream) {
                        var resposeSucces = "";
                        var mediaStreamTrack = stream.getVideoTracks()[0];
                        if (typeof mediaStreamTrack !== "undefined") {
                            mediaStreamTrack.onended = function () {//for Chrome.
                                resposeSucces = "Su camara web esta en uso por otra aplicación, detenga la aplicación y vuelva a intentarlo.";
                                resolve(resposeSucces);
                            }
                            // eliminamos la comprobación de la camara web 
                            mediaStreamTrack.stop();
                        } else {
                            resposeSucces = "La página no tiene permisos para usar la camara web y microfono, por favor habilite los permisos y vuelva a intentarlo.";
                        }
                        resolve(resposeSucces);
                    }, function (e) {
                        var responseFail = "";
                        switch (e.name) {
                            case 'NotFoundError':
                                responseFail = "No se encontro una camara web, por favor conecte una.";
                                break;
                            case 'DevicesNotFoundError':
                                responseFail = "No se encontro una camara web, por favor conecte una.";
                                break;
                            case 'SourceUnavailableError':
                                responseFail = "Su camara web esta en uso por otra aplicación, detenga la aplicación y vuelva a intentarlo.";
                                break;
                            case 'NotReadableError':
                                responseFail = "Su camara web esta en uso por otra aplicación, detenga la aplicación y vuelva a intentarlo.";
                                break;
                            case 'PermissionDeniedError':
                                responseFail = "La página no tiene permisos para usar la camara web y microfono, por favor habilite los permisos y vuelva a intentarlo.";
                                break;
                            case 'SecurityError':
                                responseFail = "La página no tiene permisos para usar la camara web y microfono, por favor habilite los permisos y vuelva a intentarlo.";
                                break;
                            default:
                                if (e.message != undefined && e.message === "Permission denied") {
                                    responseFail = "La página no tiene permisos para usar la camara web y microfono, por favor habilite los permisos y vuelva a intentarlo.";
                                }
                                else {
                                    responseFail = e.message;
                                }
                                break;
                        }
                        resolve(responseFail);
                    });
                } else {
                    response = "Explorador web incompatible, abra la página en Chrome o Firefox.";
                    resolve(response);
                }
                // } else {
                //     response = "Use protocolo https para abrir esta página.";
                // } 
                // return response;
            } catch (error) {
                response = "Error al verificar el uso de la camara web, inténtelo nuevamente";
                resolve(response);
            }
        })
    }

    /**
     * Método que valida el estatus de la agenda antes de iniciarla,
     * valida las fechas de inicio y fin y si el participante esta en incluido en la agenda.
     */
    validarParticipantAndSchedule = async () => {
        try {
            var tokenVideo = GetTokenVideoEntrevista();
            var url = `${_SERVER}/api/schedule/verificate/status/${this.state.objSesion.accessCode}/${this.state.objSesion.participantId}/${this.state.objSesion.isCandidate}`;
            const fetchDataScheduleParticipant = HeaderGet(url, tokenVideo);
            let dataResponse = await axiosExcecute(fetchDataScheduleParticipant);
            let validacionStatus = dataResponse.data;
            let validacionmensaje = "";
            switch (dataResponse.data) {
                case 0:
                    // la agenda no existe
                    validacionmensaje = "La entrevista no existe, verifique su información.";
                    break;
                case 1:
                    // la agenda se cancelo o pospuso
                    validacionmensaje = "La entrevista esta cancelada o pospuesta.";
                    break;
                case 2:
                    // es pronto para iniciar la entrevista
                    validacionmensaje = "Es pronto para iniciar la entrevista.";
                    break;
                case 3:
                    // la entrevista ya concluyo
                    validacionmensaje = "La entrevista concluyó.";
                    break;
                case 4:
                    // exito
                    break;
                case 5:
                    // el participante no esta en la entrevista
                    validacionmensaje = "El participante no esta incluido en la entrevista.";
                    break;
                default:
                    // ocurrió un error
                    validacionmensaje = "Ocurrió un error, intentelo nuevamente.";
                    break;
            }

            await this.setState({
                validacionScheduleParticipant: validacionStatus,
                validacionParticipantMessage: validacionStatus === 3 ? "" : validacionmensaje,
                habilitarVistaValidacion: !(validacionStatus === 3),
            });

        } catch (error) {
            message.error("Ocurrió un error verificar la entrevista");
        }
    }

    /**
     * Transforma el texto enriquecido a un objeto para poder 
     * inicializarlo en en componente de texto enriquecido
     * @param {*} notes 
     */
    _getInitialHTML(notes) {
        const contentBlock = htmlToDraft(notes);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        return editorState;
    }

    /**
     * Método que obtiene la informacion del candidato
     */
    getDataCandidate = async () => {
        try {
            var tokenVideo = GetTokenVideoEntrevista();
            var url = `${_SERVER}/api/schedule/${this.state.scheduleId}/candidate`;
            const fetchDataInterviewer = HeaderGet(url, tokenVideo);

            let dataCandidate = await axiosExcecute(fetchDataInterviewer);
            if (dataCandidate.status === 200 && dataCandidate.data !== null) {
                this.setState({
                    dataCandidate: dataCandidate.data,
                    objSesion: {
                        ...this.state.objSesion,
                        nombreCandidato: dataCandidate.data.name
                    }
                });
            }

        } catch (error) {
            message.error("Ocurrió un error al obtener la información del candidato");
        }
    }

    /**
     * Método que obtiene la información de la agenda mediante el código de acceso
     * @returns 
     */
    getScheduleByAccessCode = async () => {
        try {
            var tokenVideo = GetTokenVideoEntrevista();
            var url = `${_SERVER}/api/schedule/accesscode/${this.props.match.params.accessCode}`;
            const fetchScheduleByAccessCode = HeaderGet(url, tokenVideo);

            let res = await axiosExcecute(fetchScheduleByAccessCode);

            if (res.status === 200) {
                this.setState({
                    scheduleId: res.data.id,
                    objSesion: {
                        ...this.state.objSesion,
                        scheduleId: res.data.id,
                        jobProfileId: res.data.jobProfileId,
                        jobProfileName: res.data.jobProfileName,
                        subject: res.data.subject,
                    },
                    endTime: res.data.endTime
                });
            }
            return res.status;
        } catch (error) {
            message.error("Ocurrió un error al obtener la información de la agenda");
            return null;
        }
    }

    /**
     * Método que obtiene la conclusion del candidato en caso de haber registrado una peviamente
     */
    getConclusionCandidate = async () => {
        try {
            if (this.state.interviewId > 0) {

                var tokenVideo = GetTokenVideoEntrevista();
                var url = `${_SERVER}/api/candidate/conclusion/interview/${this.state.interviewId}`;
                const fetchConclusionCandidate = HeaderGet(url, tokenVideo);

                let response = await axiosExcecute(fetchConclusionCandidate);

                if (response.status === 200 && response.data !== null) {
                    this.setState({
                        conclusionCandidate: {
                            ...response.data
                        }
                    })
                }
            }

            this.setState({
                habilitarModalCandidato: true,
            });
        } catch (error) {
            this.setState({
                habilitarModalCandidato: true,
            });
        }
    }

    /**
     * Método que obtiene la conlcusion del candidato en caso de haber registrado una anteriormente
     */
    getConclusionInterviewer = async () => {
        try {
            if (this.state.interviewId > 0) {
                var tokenVideo = GetTokenVideoEntrevista();
                var url = `${_SERVER}/api/interviewer/conclusion/interview/${this.state.interviewId}/interviewer/${this.state.participantId}`;
                const fetchConclusionCandidate = HeaderGet(url, tokenVideo);

                let response = await axiosExcecute(fetchConclusionCandidate);

                if (response.status === 200 && response.data !== null) {
                    this.setState({
                        conclusionEntrevistador: {
                            ...response.data
                        }
                    })
                }
            }
            this.setState({
                habilitarModalEntrevistador: true,
            });
        } catch (error) {
            this.setState({
                habilitarModalEntrevistador: true,
            });
        }
    }

    /**
     * Método que obtiene las notas de un entrevistador 
     */
    getNoteInterviewer = async () => {
        try {

            var tokenVideo = GetTokenVideoEntrevista();
            var url = `${_SERVER}/api/interview/note/interview/interviewer/${this.state.interviewId}/${this.state.participantId}`;
            const fetchNote = HeaderGet(url, tokenVideo);

            let res = await axiosExcecute(fetchNote);

            if (res.data.length > 0) {
                this.setState({
                    habilitarNota: true,
                    noteInterviewer: res.data[0]
                });
            }
            else {
                this.setState({
                    habilitarNota: true
                });
            }
        } catch (error) {
            this.setState({
                habilitarNota: true
            });
        }
    }

    /**
     * Método que agrega un participante a la lista de participantes activos durante la entrevista
     * @param {Tipo de dato string que identifica al participante} participantId 
     * @param {Tipo de dato bool que identifica si es candidato o entrevistador} isCandidate 
     */
    addParticipantSession = async (participantId, isCandidate) => {
        try {

            let listParticipants = this.state.listParticipants;
            // creamos el objeto del participante
            var userParticipant = {
                participantId: participantId,
                isCandidate: isCandidate,
            }
            if (!isCandidate) {
                // verificamos el usuario en nuestra lista que nos ayudara a idenificar los participantes que se encuentran en la sesion.
                let interviewer = listParticipants.filter(user => user.participantId === participantId);
                if (interviewer.length === 0) {
                    listParticipants.push(userParticipant);
                    this.setState({
                        listParticipants: listParticipants,
                    });
                }

                // cambiamos el indicador del entrevistador a ingresado
                let interviewers = this.state.listInterviewer;
                let indiceParticipant = interviewers.findIndex(user => user.id === participantId);
                if (indiceParticipant >= 0) {
                    interviewers[indiceParticipant].isInInterview = true;
                    this.setState({
                        listInterviewer: interviewers
                    });
                }
            }
            else {
                // verificamos si el candidato ya se encuentra en la sesion
                let candidate = listParticipants.filter(participant => participant.participantId === participantId);
                // sino existe se crea.
                if (candidate.length === 0) {
                    // agregamos el usuario a la lista de participantes
                    listParticipants.push(userParticipant);

                    this.setState({
                        listParticipants: listParticipants,
                    });
                }
                // cambiamos el estatus del candidato a ingresado
                let dataCandidate = this.state.dataCandidate;
                dataCandidate.isInInterview = true;
                this.setState({
                    dataCandidate: dataCandidate
                });
            }
        } catch (error) {
            message.error("Ocurrió un error al agregar un participante a la entrevista");
        }
    }

    /**
     * Método que quita de los participantes activos, uno que haya salido
     * @param {Tipo de dato string que identifica la participante} participantId 
     * @param {Tipo de dato bool que identiica si es candidato o entrevistador} isCandidate 
     */
    removerParticipantSession = async (participantId, isCandidate) => {
        try {

            let listParticipants = this.state.listParticipants;

            if (!isCandidate) {
                // removemos el entrevistador de la lista de participantes
                let interviewerParticinpant = listParticipants.filter(user => user.participantId === participantId);
                if (interviewerParticinpant !== undefined && interviewerParticinpant.length > 0) {

                    let index = listParticipants.indexOf(interviewerParticinpant[0], 0);
                    if (index > -1) {
                        listParticipants.splice(index, 1);
                        this.setState({
                            listParticipants: listParticipants
                        });
                    }
                }

                // cambiamos el indicador del entrevistador a ingresado
                let interviewers = this.state.listInterviewer;
                let indiceParticipant = interviewers.findIndex(user => user.id === participantId);
                if (indiceParticipant >= 0) {
                    interviewers[indiceParticipant].isInInterview = false;
                    this.setState({
                        listInterviewer: interviewers
                    });
                }
            }
            else {
                // buscamos el participnate en la lista y si existe lo removemos
                let candidate = listParticipants.filter(user => user.participantId === participantId);
                if (candidate !== undefined && candidate.length > 0) {

                    let index = listParticipants.indexOf(candidate[0], 0);
                    if (index > -1) {
                        listParticipants.splice(index, 1);
                        this.setState({
                            listParticipants: listParticipants
                        });
                    }
                }

                // cambiamos el estatus del candidato a ingresado
                let dataCandidate = this.state.dataCandidate;
                dataCandidate.isInInterview = false;
                this.setState({
                    dataCandidate: dataCandidate
                });

            }
            // validamos el total de participantes en la reunio
            await this.verificateParticipantinSession();

        } catch (error) {
            message.error("Ocurrió un error al remover un participante de la entrevista");
        }
    }

    /**
     * Método que verifica si existen participantes activos para mantener la sesión activa
     */
    verificateParticipantinSession = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                let participantList = this.state.listParticipants;
                if (participantList.length < 1) {
                    await this.setState({
                        isInterviewInProcess: false
                    });
                    this.clearIntervalo();
                    await this.FinalizarLlamada();
                }
                resolve(true);
            } catch (error) {
                message.error("Ocurrió un error al verificar el total de participantes en la entrevista");
                resolve(false);
            }
        })
    }

    /**
     * Método que obtiene la información del participnate, ya sea candidato o entrevistador
     */
    getInformationParticipant = async () => {
        try {

            if (this.state.objSesion.isCandidate) {
                var tokenVideo = GetTokenVideoEntrevista();
                var url = `${_SERVER}/api/schedule/${this.state.scheduleId}/candidate`;
                const fetchCandidate = HeaderGet(url, tokenVideo);

                let res = await axiosExcecute(fetchCandidate);

                if (res.status === 200 && res.data != null) {
                    this.setState({
                        objSesion: {
                            ...this.state.objSesion,
                            nombreParticipant: res.data.name,
                            urlAvatar: res.data.UrlAvatar,
                        },
                    });
                }
            }
            else {
                var tokenVideo = GetTokenVideoEntrevista();
                var url = `${_SERVER}/api/schedule/interviewer/schedule/${this.state.scheduleId}/interviewer/${this.state.participantId}/information`;
                const fetchInterviewer = HeaderGet(url, tokenVideo);

                let res = await axiosExcecute(fetchInterviewer);

                if (res.status === 200 && res.data !== null) {
                    this.setState({
                        objSesion: {
                            ...this.state.objSesion,
                            nombreParticipant: res.data.name,
                            urlAvatar: res.data.urlAvatar,
                        },
                    });
                }
            }

        } catch (error) {
            message.error("Ocurrió un error al obtener la información del participante");
        }
    }

    /**
     * Método que obtiene la información de los entrevistadores agendados
     */
    getInfoInterviewer = async () => {
        try {
            var tokenVideo = GetTokenVideoEntrevista();
            var url = `${_SERVER}/api/schedule/interviewer/schedule/${this.state.scheduleId}`;
            const fetchInterviewer = HeaderGet(url, tokenVideo);

            let res = await axiosExcecute(fetchInterviewer);
            if (res.status === 200) {
                this.setState({
                    listInterviewer: res.data
                })
            }
        } catch (error) {
            message.error("Ocurrió un error al obtener la información de los entrevistadores");
        }
    }

    /**
     * Método que obtiene el estatus del participante, ya sea candidato o entrevistador
     * @returns 
     */
    getParticipantStatus = async () => {
        try {
            if (this.state.isCandidate) {
                var tokenVideo = GetTokenVideoEntrevista();
                var url = `${_SERVER}/api/schedule/candidate/${this.state.scheduleId}/status`;
                const fetchScheduleStatus = HeaderGet(url, tokenVideo);

                let res = await axiosExcecute(fetchScheduleStatus);

                if (res.status === 200 && res.data !== null) {
                    this.setState({
                        estatusParticipante: res.data.statusTypeId
                    });
                }
            }
            else {
                var tokenVideo = GetTokenVideoEntrevista();
                var url = `${_SERVER}/api/schedule/interviewer/schedule/${this.state.scheduleId}/interviewer/${this.state.participantId}`;
                const fetchScheduleInterviewer = HeaderGet(url, tokenVideo);

                let res = await axiosExcecute(fetchScheduleInterviewer);

                if (res.status === 200 && res.data !== null) {
                    this.setState({
                        estatusParticipante: res.data.statusTypeId
                    });
                }
            }
            return true;
        } catch (error) {
            message.error("Ocurrió un error al obtener el estatus del participante");
            return null;
        }
    }

    /**
     * Método que elimina el oyente para verificar si se pidio mas tiempo para la entrevista
     */
    clearIntervaloVerificarTiempo = () => {
        if (this.state.idIntervaloMoreTime !== null) {
            let idIntervalo = this.state.idIntervaloMoreTime;
            clearInterval(idIntervalo);

            this.setState({
                idIntervaloMoreTime: null
            });
        }
    }

    /**
     * Metodo que inicializa el oyente para verificar si se pidio mas tiempo para la entrevista
     */
    startInervaloVerificarMasTiempo = async () => {
        let timeId = setInterval(() => {
            this.getInterviewbyId();
        }, 1000);

        this.setState({
            idIntervaloMoreTime: timeId
        });
    }

    /**
     * Método que obtiene una entrevista por su ID
     */
    getInterviewbyId = async () => {
        try {
            var tokenVideo = GetTokenVideoEntrevista();
            var url = `${_SERVER}/api/interview/${this.state.interviewId}`;
            const fetchInterview = HeaderGet(url, tokenVideo);

            let res = await axiosExcecute(fetchInterview);

            if (res.status === 200 && res.data !== null) {
                this.setState({
                    moreTime: res.data.isMoreTime,
                });
            }
        } catch (error) {
            message.error("Ocurrió un error al obtener la entrevista");
        }
    }

    /**
     * Metodo que obtiene el interview por el id de la agenda
     */
    getInterviewByScheduleId = async () => {
        if (this.state.scheduleId !== 0) {
            try {
                // obtenemos el status del participante
                await this.getParticipantStatus();

                var tokenVideo = GetTokenVideoEntrevista();
                var urlInterview = `${_SERVER}/api/interview/schedule/${this.state.scheduleId}`;
                const fetchInterview = HeaderGet(urlInterview, tokenVideo);
                let res = await axiosExcecute(fetchInterview);


                var urlCandidate = `${_SERVER}/api/schedule/${this.state.scheduleId}/candidate`;
                const fetchCandidate = HeaderGet(urlCandidate, tokenVideo);
                let dataCandidate = await axiosExcecute(fetchCandidate);


                if (res.status === 200 && res.data !== null) {
                    this.setState({
                        startTime: res.data.startTime,
                        moreTime: res.data.isMoreTime,
                        estatusEntrevista: res.data.statusTypeId,
                        interviewId: res.data.id,
                        dataCandidate: dataCandidate.data,
                    });
                    // si el status es 4 y no hay id de intervalo se inicia el oyente
                    if (this.state.estatusParticipante === 4 && this.state.idIntervalo === null) {
                        this.startIntervalInterview();
                    }
                    // si existe el oyente y el estatus es diferente de espera se remueve el oyente
                    if (this.state.idIntervalo != null && res.data.statusTypeId === 6 && this.state.estatusParticipante === 5) {
                        this.clearIntervalo();
                    }
                    // si el estatus es 6 mostramos las secciones laterales de la entrevista
                    if (res.data.statusTypeId === 6 && this.state.estatusParticipante === 5) {
                        this.IniciarLlamada();
                    }

                    // si el participante salio de la entrevista pero esta sigue en proceso
                    if (res.data.statusTypeId === 6 && this.state.estatusParticipante === 7) {
                        this.verificarTimeToRestarInterview(res.data.isMoreTime);
                    }
                    // si la entrevista ya concluto y el id del intervalo sige activo detenemos el proceso
                    if (res.data.statusTypeId === 7 && this.state.idIntervalo !== null) {
                        this.setState({
                            isInterviewInProcess: false
                        });
                        this.clearIntervalo();
                    }
                }
                return res.status;
            } catch (error) {
                return null;
            }
        }
    }

    verificarTimeToRestarInterview(isMoreTime) {
        try {
            var currentTime = new Date();
            var habilitarVolveriniicar = true;
            var horaTermino = new Date(this.state.endTime);
            // si se solicita mas tiempo se agrega el tiempo a la hora de cierre
            if (isMoreTime) {
                horaTermino.setMinutes(horaTermino.getMinutes() + _MAX_MORE_MINUTES_INTERVIEW);
            }
            // restamos los minutos de espera para volver a entrar a la entrevista
            horaTermino.setMinutes(horaTermino.getMinutes() - _MIN_ESPERA_PARTICIPANTE);

            if (this.state.idIntervalo === null) {
                if (currentTime.getTime() >= horaTermino.getTime()) {
                    habilitarVolveriniicar = false;
                }
                else {
                    this.startIntervalInterview();
                }
            }
            else {
                if (currentTime.getTime() >= horaTermino.getTime()) {
                    habilitarVolveriniicar = false;
                    this.clearIntervalo();
                }
            }

            this.setState({
                isInterviewInProcess: habilitarVolveriniicar
            });

        } catch (error) {
            message.error("Ocurrió un error al verificar el tiempo válido para volver a ingresar a la entrevista");
        }
    }

    /**
     * Método que requiere mas tiempo para la entrevista cuando esta apunto de terminarse
     */
    requestMoreTime = async () => {
        try {
            var tokenVideo = GetTokenVideoEntrevista();
            var url = `${_SERVER}/api/interview/request/time/interview/${this.state.interviewId}`;
            const fetchMoreTime = HeaderPut(url, tokenVideo, {});

            let response = await axiosExcecute(fetchMoreTime);

            if (response.status === 200) {
                this.setState({
                    moreTime: true,
                });
            }
        } catch (error) {
            message.error("Ocurrió un error al agregar más tiempo a la entrevista");
            this.setState({
                moreTime: false,
            });
        }
    }

    /**
     * Método que crea una sala 
     */
    IrEstoyListo = async () => {
        try {
            let interviewOpen = {
                "id": 0,
                "accessCode": this.state.objSesion.accessCode,
                "participantId": this.state.participantId,
                "isCandidate": this.state.isCandidate
            }

            var tokenVideo = GetTokenVideoEntrevista();
            var url = `${_SERVER}/api/interview/open`;
            const fetchMoreTime = HeaderPost(url, tokenVideo, interviewOpen);

            let response = await axiosExcecute(fetchMoreTime);


            if (response.status === 200) {
                this.setState({
                    estatusEntrevista: response.data.statusTypeId,
                    interviewId: response.data.id,
                    moreTime: response.data.isMoreTime,
                });

                if (response.data.statusTypeId === 4) {
                    this.startIntervalInterview();
                }
                else {
                    await this.getInterviewByScheduleId();
                }
            }
            return response.status;
        } catch (error) {
            message.error("Ocurrió un error al iniciar la entrevista");
            return null;
        }
    };

    /**
     * Metodo que inicializa el oyente para verificar el status de la entrevista
     */
    startIntervalInterview = async () => {
        let timeId = setInterval(() => {
            this.getInterviewByScheduleId();
        }, 2000);

        this.setState({
            idIntervalo: timeId
        });
    }

    /**
     * Método que elimina el oyente para validar el estatus de la entrevista
     */
    clearIntervalo = () => {
        try {
            let idIntervalo = this.state.idIntervalo;
            if (idIntervalo !== null) {
                clearInterval(idIntervalo);

                this.setState({
                    idIntervalo: null
                })
            }
        } catch (error) {
            message.error("Ocurrió un error al eliminar el oyente para validar estatus de entrevista");
        }
    }

    /**
     * Método que habilita la sección de acciones para el entrevistador
     */
    IniciarLlamada = () => {
        this.setState({
            estilocontenedor: "contenedor-video  ",
            mostrarSeccionComponentes: true
        });
    }

    /**
     * Método que finaliza la sesión para el participante
     */
    FinalizarLlamada = async () => {

        cookies.set("preguntasProfundas", {
            preguntasProfundas: this.state.preguntasProfundas,
            headerDeepList: this.state.headerDeepList,
        })

        return new Promise(async (resolve, reject) => {
            try {
                if (this.state.isCandidate) {
                    this.setState({
                        mostrarModalEntrevistador: false,
                        mostrarModalCandidato: true,
                        estilocontenedor: "contenedor-inicio ",
                        mostrarSeccionComponentes: false,
                    })
                }
                else {
                    this.setState({
                        mostrarModalEntrevistador: true,
                        mostrarModalCandidato: false,
                    });
                }
                // finalizamos la llamada del participante
                var isSaved = this.colgarLlamada();
                if (!this.state.isCandidate) {
                    this.guardarGuiaPreguntas();
                    this.saveOrUpdateNote();
                }
                this.setState({
                    showPreguntas: false,
                });
                let participantList = this.state.listParticipants;
                if (participantList.length < 1) {
                    this.finalizarEntrevista();
                }
                resolve(true);
            } catch (error) {
                message.error("Ocurrió un error al finalizar la entrevista");
                resolve(false);
            }
        });
    }

    /**
     * Método que finaliza la entrevista
     */
    finalizarEntrevista = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                var tokenVideo = GetTokenVideoEntrevista();
                var url = `${_SERVER}/api/interview/end/interview/${this.state.interviewId}`;
                const fetchFinEntrevista = HeaderPut(url, tokenVideo, {});

                let response = await axiosExcecute(fetchFinEntrevista);

                if (response.status === 200 && response.data === 200) {
                    await this.getParticipantStatus();
                    await this.getInterviewByScheduleId();
                }
                resolve(true);
            } catch (error) {
                message.error("Ocurrió un error al finalizar la entrevista");
                resolve(false);
            }
        });
    }

    /**
     * Método que finaliza la sesión de un participante
     * @returns 
     */
    colgarLlamada = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                let participantEndSession = {
                    "id": 0,
                    "scheduleId": this.state.scheduleId,
                    "ParticipantId": this.state.participantId,
                    "interviewId": this.state.interviewId,
                    "isCandidate": this.state.isCandidate
                }
                var tokenVideo = GetTokenVideoEntrevista();
                var url = `${_SERVER}/api/interview/end/session/participant`;
                const fetchColgar = HeaderPut(url, tokenVideo, participantEndSession);

                let response = await axiosExcecute(fetchColgar);

                if (response.status === 200) {
                    await this.getParticipantStatus();
                    await this.getInterviewByScheduleId();
                }
            } catch (error) {
                message.error("Ocurrió un error al finalizar la sesión del participante");
            }
            resolve(true);
        });
    }

    /**
     * Método que redirige a la vista de entrevistas
     */
    IrEntrevistas = () => {
        goToEntrevista();
    }

    /**
     * Método que cierra el modal de conclusión del candidato o entrevistador
     */
    CerrarModal = () => {
        this.setState({
            mostrarModalCandidato: false,
            mostrarModalEntrevistador: false,
        })
    }

    /**
     * Método que almacena las respuestas a las preguntas profundas
     * @param {Objeto de pregunta} pregunta 
     */
    handleRespuestaPreguntaProfundas = (pregunta) => {

        var question = {
            id: pregunta.idPregunta,
            text: pregunta.text,
            answer: pregunta.answer,
            order: pregunta.orden,
            seccionId: pregunta.seccionId,
        }

        var header = {
            seccionId: pregunta.seccionId,
            seccionName: pregunta.seccionName
        }
        // verificamos si existe la pregunta, si existe se actualiza la respuesta de lo contrario se crea el objeto.
        var preguntasProfundasList = this.state.preguntasProfundas;
        if (preguntasProfundasList.length > 0) {
            var indexPregunta = preguntasProfundasList.findIndex(respuesta => respuesta.id === pregunta.idPregunta && respuesta.seccionId === pregunta.seccionId);
            if (indexPregunta >= 0) {
                preguntasProfundasList[indexPregunta].answer = pregunta.answer;
            }
            else {
                preguntasProfundasList.push(question);
            }
        }
        else {
            preguntasProfundasList.push(question);
        }
        // verificamos si existe el encabezado de la pregunta si no existe se crea.
        var headerDeepList = this.state.headerDeepList;
        if (headerDeepList.length > 0) {
            var indexHeader = headerDeepList.findIndex(heade_ => heade_.seccionId === header.seccionId);
            if (indexHeader < 0) {
                headerDeepList.push(header);
            }
        }
        else {
            headerDeepList.push(header);
        }


        this.setState({
            preguntasProfundas: preguntasProfundasList,
            headerDeepList: headerDeepList,
        });
    }

    /**
     * Método que almacena los valores del score de cada pregunta por competencias
     * @param {Objeto tipo header de pregunta} headerCompetencia 
     */
    handleChangeHeaderScore = (headerCompetencia) => {

        var headerList = this.state.HeaderCompetenciasList;
        if (headerList.length > 0) {

            var indexHeader = headerList.findIndex(itemHeader => itemHeader.id === headerCompetencia.id);
            if (indexHeader >= 0) {
                headerList[indexHeader].score = headerCompetencia.score;
            }
            else {
                headerList.push(headerCompetencia);
            }
        }
        else {
            headerList.push(headerCompetencia);
        }

        this.setState({
            HeaderCompetenciasList: headerList,
        });
    }

    /**
     * Método para alamcenar la respuesta de una pregunta competencia y el score de la pregunta
     * @param {Objeto tipo pregunta profunda} pregunta 
     * @param {Objeto tipo header competencia} headerCompetencias 
     */
    handleRespuestaPreguntaCompetencia = (pregunta, headerCompetencias) => {

        var question = {
            id: pregunta.preguntaId,
            text: pregunta.text,
            answer: pregunta.answer,
            order: pregunta.orden,
            seccionId: pregunta.seccionId
        }
        // verificamos si existe la pregunta y si existe se actualiza la respues, si no existe la creamos
        var preguntasCompetenciasList = this.state.preguntasCompetencias;
        if (preguntasCompetenciasList.length > 0) {
            var indicePregunta = preguntasCompetenciasList.findIndex(respuesta => respuesta.id === pregunta.preguntaId && respuesta.seccionId === pregunta.seccionId);
            if (indicePregunta >= 0) {
                preguntasCompetenciasList[indicePregunta].answer = pregunta.answer;
            }
            else {
                preguntasCompetenciasList.push(question);
            }
        }
        else {
            preguntasCompetenciasList.push(question);
        }
        // verificamos si existe el encabezado de la seccion de preguntas, si existe se actualiza el score, si no existe creamos el encabezado.
        var headerList = this.state.HeaderCompetenciasList;
        if (headerList.length > 0) {

            var indexHeader = headerList.findIndex(itemHeader => itemHeader.id === headerCompetencias.id);
            if (indexHeader >= 0) {
                headerList[indexHeader].score = headerCompetencias.score;
            }
            else {
                headerList.push(headerCompetencias);
            }
        }
        else {
            headerList.push(headerCompetencias);
        }

        this.setState({
            preguntasCompetencias: preguntasCompetenciasList,
            HeaderCompetenciasList: headerList,
        });
    }

    /**
     * Método que guarda la guia de entrevistas
     */
    guardarGuiaPreguntas = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                // obtenemos la lista de preguntas por competencias para guardar la guia contestada
                var questiosnCompetence = this.getListaPreguntasCompetenciasGuardar();
                // obtenemos la lista de preguntas profundas para guardar la guia contestada
                var questionsDeep = this.getListaPreguntasDeepGuardar();

                var guiaPreguntas = {
                    "interviewId": this.state.interviewId,
                    "JobProfileId": this.state.objSesion.jobProfileId,
                    "candidateId": this.state.dataCandidate.id,
                    "InterviewerId": this.state.participantId,
                    "deepInterview": {
                        "interviewerId": this.state.participantId,
                        "topics": questionsDeep
                    },
                    "CompetenceInterview": {
                        "interviewerId": this.state.participantId,
                        "competences": questiosnCompetence
                    },
                }

                var tokenGuide = GetTokenGuiaEntrevista();
                var header = {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': 'Bearer ' + tokenGuide,
                }

                let responseSaveQuestiosn = await axios.post(_SERVER_GUIA_ENTREVISTA + '/interviewer/answer ', guiaPreguntas, { "headers": header });

                resolve(true);
            } catch (error) {
                message.error("Ocurrió un error al guardar la guía de entrevistas contestada");
                if (error.response.status === 401) {
                    await this.refresTokenGuide();
                }
                resolve(false);
            }
        });
    }

    /**
     * Método que refresca el token para la guia de entrevistas
     */
    refresTokenGuide = async () => {
        try {
            var auth = cookies.get('auth');
            let email = auth.user.email;
            let refrest = await axios.post(`${_SERVER_GUIA_ENTREVISTA}/authorization/token?email=${email}`, {});
            if (refrest.status === 200) {

                auth.tokenGuia = refrest.data.token;
                // guardamos la cookie
                SaveCookeSession(auth);
                await this.guardarGuiaPreguntas();
            }
        } catch (error) {
            message.error("Ocurrió un error al refrescar el token para la guía de entrevista");
        }
    }

    /**
     * Método que obtiene las respuestas guardadas a las preguntas por competencias. 
     * @returns 
     */
    getListaPreguntasCompetenciasGuardar = () => {
        try {
            // preguntas competencias
            var questiosnCompetence = [];
            var questionCompetenceList = this.state.preguntasCompetencias;
            this.state.HeaderCompetenciasList.forEach(header => {

                var questions = [];
                var questionsCompetenceByHeader = questionCompetenceList.filter(pregunta => pregunta.seccionId === header.id);
                questionsCompetenceByHeader.forEach(question => {
                    // convertimos del objeto de texto enriquecido a texto plano
                    var textoEnriquecido = question.answer;
                    var respuesta = this.convertirHtml(textoEnriquecido);
                    question.answer = respuesta;

                    questions.push(question);
                });

                var competence = {
                    "competence": {
                        "id": header.id,
                        "name": null
                    },
                    "score": header.score,
                    "questions": questions
                }

                questiosnCompetence.push(competence);
            });
            return questiosnCompetence;
        } catch (error) {
            message.error("Ocurrió un error al crear la lista de pregutas por competencias para guardar");
            var preguntas = [];
            return preguntas;
        }
    }

    /**
     * Método que obtiene la lista de respuestas de las preguntas profundas
     * @returns 
     */
    getListaPreguntasDeepGuardar = () => {
        try {
            // preguntas profundas
            var questionsDeep = [];
            var questionDeepList = this.state.preguntasProfundas;
            this.state.headerDeepList.forEach(header => {
                var questions = [];
                var questionDeepByHeader = questionDeepList.filter(question => question.seccionId === header.seccionId);

                questionDeepByHeader.forEach(question => {
                    // convertimos del objeto de texto enriquecido a texto plano
                    var textoEnriquecido = question.answer;
                    var respuesta = this.convertirHtml(textoEnriquecido);
                    question.answer = respuesta;

                    questions.push(question);
                });

                var topic = {
                    "id": header.seccionId,
                    "name": header.seccionName,
                    "questions": questions
                }

                questionsDeep.push(topic);
            });

            return questionsDeep;
        } catch (error) {
            message.error("Ocurrió un error al crear la lista de pregutas profundas para guardar");
            var preguntas = [];
            return preguntas;
        }
    }

    /**
     * Método que obtiene los datos del modal de entrevista y manda a guardar o actualizar
     * @param {Objeto formulario de conclusion de entrevistador} form 
     */
    handleModalEntrevistador = async (form) => {
        this.setState({
            conclusionEntrevistador:
            {
                id: this.state.conclusionEntrevistador.id,
                interviewId: this.state.interviewId,
                interviewerId: this.state.participantId,
                scoreCompatibility: form.scoreCompatibility,
                advantageHiring: form.advantageHiring,
                riskHiring: form.riskHiring,
                conclusion: form.conclusion
            }
        });
        setTimeout(() => {
            if (this.state.conclusionEntrevistador.id !== undefined && this.state.conclusionEntrevistador.id !== 0) {
                this.updateInterviewConclusion();
            }
            else {
                this.saveInterviewerConcluision();
            }

        }, 1000);
    }

    /**
     * Método que obtiene los datos del modal de candidato y manda a guardar o actualizar
     * @param {Objeto formulario de conclusion de candidato} form 
     */
    handleModalCandidato = async (form) => {
        this.setState({
            conclusionCandidate: {
                id: this.state.conclusionCandidate.id,
                interviewId: this.state.interviewId,
                conclusion: form.conclusion,
                score: form.score
            }
        });
        setTimeout(() => {
            if (this.state.conclusionCandidate.id !== undefined && this.state.conclusionCandidate.id !== 0) {
                this.updateCandidateConclusion();
            }
            else {
                this.saveCandidateConclusion();
            }

        }, 1000);
    }

    /**
     * Método que manda a guardar o actualizar una nota de entrevistador
     */
    saveOrUpdateNote = async () => {
        return new Promise(async (resolve, reject) => {
            await this.setState({
                IsSavedNote: false,
                notaSaveProcess: true
            });

            if (this.state.noteInterviewer.id === 0) {
                await this.saveNote();
            }
            else {
                await this.updateNote();
            }

            resolve(true);
        });
    }

    /**
     * Método que guarda una nota de entrevistador
     */
    saveNote = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                let note = this.convertirHtml(this.state.nota);
                if (note != null) {

                    let interviewerNoteDto = {
                        "id": 0,
                        "interviewId": this.state.interviewId,
                        "interviewerId": this.state.participantId,
                        "note": note
                    }
                    var tokenVideo = GetTokenVideoEntrevista();
                    var url = `${_SERVER}/api/interview/note`;
                    const fetchSaveNote = HeaderPost(url, tokenVideo, interviewerNoteDto);

                    let response = await axiosExcecute(fetchSaveNote);

                    if (response.status === 201 && response.data !== null) {
                        this.setState({
                            noteInterviewer: response.data,
                            IsSavedNote: true,
                            notaSaveProcess: false
                        });
                    }
                    else {
                        this.setState({
                            IsSavedNote: false,
                            notaSaveProcess: false
                        });
                    }
                }
                resolve(true);
            } catch (error) {
                message.error("Ocurrió un error al guardar las notas");

                this.setState({
                    IsSavedNote: false,
                    notaSaveProcess: false
                });
                resolve(false);
            }
        });
    }

    /**
     * Método que actualiza una nota de entrevistador
     */
    updateNote = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                let note = this.convertirHtml(this.state.nota);
                if (note != null) {
                    let interviewNote = this.state.noteInterviewer;
                    interviewNote.note = note;

                    var tokenVideo = GetTokenVideoEntrevista();
                    var url = `${_SERVER}/api/interview/note`;
                    const fetchUpdateNote = HeaderPut(url, tokenVideo, interviewNote);

                    let res = await axiosExcecute(fetchUpdateNote);

                    if (res.status === 200) {
                        this.setState({
                            noteInterviewer: interviewNote,
                            IsSavedNote: true,
                            notaSaveProcess: false
                        });
                    }
                    else {
                        this.setState({
                            IsSavedNote: false,
                            notaSaveProcess: false
                        });
                    }
                }
                resolve(true);
            } catch (error) {
                message.error("Ocurrió un error al actualizar las notas");

                this.setState({
                    IsSavedNote: false,
                    notaSaveProcess: false
                });

                resolve(false);
            }
        });
    }

    /**
     * Método que guarda una grabación de la entrevista.
     * @param {Tipo de dato string que identifica a la url del video} urlVideo 
     * @param {Tipo de dato string que identifica al nombre del video} videoName 
     * @returns 
     */
    saveVideo = async (idRecordin) => {
        try {
            if (idRecordin !== undefined && idRecordin !== "") {
                let stopVideoOpenVidu = {
                    "interviewId": this.state.interviewId,
                    "recordinId": idRecordin,
                }

                var tokenVideo = GetTokenVideoEntrevista();
                var url = `${_SERVER}/api/video/detenerGrabacion`;
                const fetchSaveVideo = HeaderPost(url, tokenVideo, stopVideoOpenVidu);

                let response = await axiosExcecute(fetchSaveVideo);
                if (response.status === 200) {
                    var grabacionIdExiste = localStorage.getItem("idGrabacionIniciada");
                    if (grabacionIdExiste !== null && grabacionIdExiste !== undefined && grabacionIdExiste !== "") {
                        // removemos la variable global del id de la grabación
                        localStorage.removeItem("idGrabacionIniciada");
                    }
                }
                return response.status;
            }
        } catch (error) {
            message.error("Ocurrió un error al guardar la grabación de la entrevista");
            return 500;
        }
    }

    /**
     * Métoto que almacena el texto enriquecido de las notas
     * @param {Objeto html de texto enriquecido} nota 
     */
    handleNota = (nota) => {
        this.setState({
            nota: nota
        });
    }

    /***
     * Método que convierte el objeto de texto enriquecido a string
     */
    convertirHtml = (textoAconvertir) => {
        try {
            if (textoAconvertir !== undefined && textoAconvertir !== null && typeof textoAconvertir !== 'string') {
                let textoCovertido = draftToHtml(convertToRaw(textoAconvertir.getCurrentContent()));
                // removemos los hipervinculos
                textoCovertido = textoCovertido.replace(/href=("|')(.*?)("|')/g, '');
                return textoCovertido;
            }
            return textoAconvertir;

        } catch (error) {
            message.error("Ocurrió un error al convertir el texto enriquecido");
            return null;
        }
    }

    /**
     * Método que guarda una conclusion de un candidato
     * @returns 
     */
    saveCandidateConclusion = async () => {
        try {
            var tokenVideo = GetTokenVideoEntrevista();
            var url = `${_SERVER}/api/candidate/conclusion`;
            const fetchSaveConclusionCandidate = HeaderPost(url, tokenVideo, this.state.conclusionCandidate);

            let response = await axiosExcecute(fetchSaveConclusionCandidate);

            if (response.status === 200 && response.data != null) {
                this.setState({
                    errorConclusion: false,
                    exitoConclusion: true
                });
            }
            return response.status;
        } catch (error) {
            this.setState({
                errorConclusion: true,
                exitoConclusion: false
            });
            message.error("Ocurrió un error al guardar la conclusión del candidato");
            return null;
        }
    }

    /**
     * Método que actualiza una conclusión del candidato
     */
    updateCandidateConclusion = async () => {
        try {
            var tokenVideo = GetTokenVideoEntrevista();
            var url = `${_SERVER}/api/candidate/conclusion`;
            const fetchUpdateConclusionCandidate = HeaderPut(url, tokenVideo, this.state.conclusionCandidate);

            let response = await axiosExcecute(fetchUpdateConclusionCandidate);

            if (response.status === 200 && response.data !== null) {
                this.setState({
                    errorConclusion: false,
                    exitoConclusion: true
                });
            }
        } catch (error) {
            message.error("Ocurrió un error al actualizar la conclusión del candidato");
            this.setState({
                errorConclusion: true,
                exitoConclusion: false
            });
        }
    }

    /**
     * Método que guarda una conclusión del entrevistador
     * @returns 
     */
    saveInterviewerConcluision = async () => {
        try {
            var tokenVideo = GetTokenVideoEntrevista();
            var url = `${_SERVER}/api/interviewer/conclusion`;
            const fetchSaveConclusionInterview = HeaderPost(url, tokenVideo, this.state.conclusionEntrevistador);

            let response = await axiosExcecute(fetchSaveConclusionInterview);

            if (response.status === 200 && response.data !== null) {
                this.setState({
                    errorConclusion: false,
                    exitoConclusion: true
                });
            }
            return response.status;
        } catch (error) {
            message.error("Ocurrió un error al guardar la conclusión del entrevistador");
            this.setState({
                errorConclusion: true,
                exitoConclusion: false
            });
            return null;
        }
    }

    /**
     * Método que actualiza la conclusión del entrevistador
     */
    updateInterviewConclusion = async () => {
        try {
            var tokenVideo = GetTokenVideoEntrevista();
            var url = `${_SERVER}/api/interviewer/conclusion`;
            const fetchUpdateConclusionInterviewer = HeaderPut(url, tokenVideo, this.state.conclusionEntrevistador);

            let response = await axiosExcecute(fetchUpdateConclusionInterviewer);

            if (response.status === 200 && response.data !== null) {
                this.setState({
                    errorConclusion: false,
                    exitoConclusion: true
                });
            }
        } catch (error) {
            message.error("Ocurrió un error al actualizar la conclusión del entrevistador");
            this.setState({
                errorConclusion: true,
                exitoConclusion: false
            });
        }
    }

    /**
     * Método que almacena la respuesta a la pregunta profunda
     * @param {Objeto tipo formulario pregunta profunda} form 
     */
    handlePreguntasProfundasEntrevistador = (form) => {
        let preguntasProfundasEntrevistador = this.state.form.PreguntasProfundasEntrevistador;
        preguntasProfundasEntrevistador.push(form);
        this.setState({
            form: {
                ...this.state.form,
                PreguntasProfundasEntrevistador: preguntasProfundasEntrevistador
            }
        })
    }

    verReporte = () => {
        var url = "#";
        this.props.history.push(url);
    }

    /**
     * Método que cierra el modal de error
     */
    handleCloseModalErrors = () => {

        var showModalConclusionCandidato = false;
        var showModalConclusionEntrevistador = false;

        if (this.state.errorConclusion) {
            if (this.state.isCandidate) {
                showModalConclusionCandidato = true;
            }
            else {
                showModalConclusionEntrevistador = true;
            }
        }

        this.setState({
            errorConclusion: false,
            exitoConclusion: false,
            mostrarModalEntrevistador: showModalConclusionEntrevistador,
            mostrarModalCandidato: showModalConclusionCandidato
        })
    }

    /**
     * Método que vuelve a iniciar la entrevista en caso de salirme y que la entrevista aun siga en curso
     */
    goBackInterview = async () => {
        // volvemos a ingresar a la entrevista
        if (this.state.isInterviewInProcess) {
            this.setState({
                showPreguntas: true,
                preguntasProfundas: [],
                preguntasCompetencias: [],
                HeaderCompetenciasList: [],
                headerDeepList: [],
                form: {
                    ...this.state.form,
                    PreguntasProfundasEntrevistador: [],
                    PreguntasCompetencias: [],
                },
            });
            await this.IrEstoyListo();
        }
        this.clearIntervalo();
    }

    /**
     * Método que idntifica el tab selccionado
     * @param {Tipo de dato string que identifica al tab} key 
     */
    selecTab = (key) => {
        this.setState({
            seccionTab: key,
        });
    }

    render() {
        return (
            <React.Fragment>
                <Header colgarLLamada={this.FinalizarLlamada} />
                {(this.state.validacionScheduleParticipant === 4 || this.state.validacionScheduleParticipant === 3) ? <div className="container contenedor-vacante">
                    <Row>
                        <Col md="auto" className="pr-0">
                            <label className="contenedor-vacante__text">Video entrevista | Vacante: </label> <label className="contenedor-vacante__text contenedor-vacante__text--bold">{this.state.objSesion.jobProfileName}</label>
                        </Col>
                        <Col md="auto">
                            <img src={iconoAgregarParticipant} alt="" />
                        </Col>
                    </Row>
                </div> : null}

                {/* <div className="contenedor-principal"> */}
                <div  >
                    <Container fluid style={{ padding: '1% 9%' }}>
                        {(this.state.validacionScheduleParticipant === 4 || this.state.validacionScheduleParticipant === 3) ?
                            <Row className={this.state.estilocontenedor}>
                                <Col xl={this.state.mostrarSeccionComponentes ? 8 : 12} md={this.state.mostrarSeccionComponentes ? 7 : 12} sm={12} >
                                    <div className="seccion-video">
                                        {this.state.scheduleId !== 0 &&
                                            <EncabezadoEntrevistador schedule={this.state.scheduleId} />
                                        }
                                        <Card className="border-0 externo">
                                            {(this.state.browserSoport) &&
                                                <Card.Body className="p-0 inner">
                                                    {this.state.estatusParticipante !== 4 && this.state.estatusParticipante !== 5 &&
                                                        <ContenidoEstoyListo
                                                            listo={() => this.IrEstoyListo()}
                                                            isCandidate={this.state.isCandidate}
                                                            interviewers={this.state.listInterviewer.length}
                                                        />
                                                    }
                                                    {this.state.estatusEntrevista === 4 && this.state.estatusParticipante === 4 &&
                                                        <EsperaParticipantes />
                                                    }
                                                    {(this.state.estatusEntrevista === 6 && this.state.estatusParticipante === 5 && this.state.objSesion.nombreParticipant !== "" && this.state.startTime !== "") &&
                                                        <VideoParticipante finalizar={() => this.FinalizarLlamada()}
                                                            objSession={this.state.objSesion}
                                                            addParticipant={this.addParticipantSession}
                                                            removeParticipant={this.removerParticipantSession}
                                                            startTime={this.state.startTime}
                                                            endTime={this.state.endTime}
                                                            isMoreTime={this.state.moreTime}
                                                            requestMoreTime={this.requestMoreTime}
                                                            startIntervalo={this.startInervaloVerificarMasTiempo}
                                                            clearIntervalo={this.clearIntervaloVerificarTiempo}
                                                            idIntervaloMoreTime={this.state.idIntervaloMoreTime}
                                                            goToChat={() => this.selecTab("chat")}
                                                            isFinaliced={this.state.validacionScheduleParticipant}
                                                            saveVideo={this.saveVideo} />
                                                    }
                                                </Card.Body>
                                            }
                                            {/* no soportado por el browser */}
                                            {(!this.state.browserSoport) &&
                                                <Card.Body className="p-0 inner">
                                                    <BrowserSopoert irEntrevistas={() => this.IrEntrevistas()} />
                                                </Card.Body>
                                            }
                                        </Card>
                                    </div>
                                </Col>
                                {this.state.mostrarSeccionComponentes === true &&
                                    <Col xl={4} md={5} sm={12} className="pl-0">
                                        <div className="secciones">
                                            <Tabs className="ml-4 mr-4 mb-0 mt-4 tab-secciones" defaultActiveKey={this.state.seccionTab} id="uncontrolled-tab-example" onSelect={(k) => this.selecTab(k)} activeKey={this.state.seccionTab} >
                                                {this.state.isCandidate === false &&
                                                    <Tab eventKey="general" title={<img src={imgGrafica} alt="" className={this.state.seccionTab === "general" ? "secciones__iconos secciones__iconos--active" : "secciones__iconos "} />} >
                                                        {this.state.objSesion.jobProfileId !== null && this.state.dataCandidate.id !== undefined && <DatosGenerales
                                                            verReporte={this.verReporte}
                                                            objSesion={this.state.objSesion}
                                                            candidateId={this.state.dataCandidate.id}
                                                        />}
                                                    </Tab>
                                                }
                                                {this.state.isCandidate === false &&
                                                    <Tab eventKey="entrevista" title={<img src={imgList} alt="" className={this.state.seccionTab === "entrevista" ? "secciones__iconos secciones__iconos--active" : "secciones__iconos "} />}>
                                                        {(this.state.objSesion.jobProfileId !== null && this.state.dataCandidate.id !== undefined && this.state.interviewId !== 0 && (this.state.estatusParticipante === 6 || this.state.estatusParticipante === 5)) ? <Preguntas
                                                            handlePreguntasProfundasEntrevistador={this.handlePreguntasProfundasEntrevistador}
                                                            handleRespuestasProfundas={this.handleRespuestaPreguntaProfundas}
                                                            handleRespuestasCompetencias={this.handleRespuestaPreguntaCompetencia}
                                                            handleChangeHeaderScore={this.handleChangeHeaderScore}
                                                            objSesion={this.state.objSesion}
                                                            candidateId={this.state.dataCandidate.id}
                                                            interviewId={this.state.interviewId}
                                                        /> : <div>
                                                            <Card className="border-0">
                                                                <Card.Body className="header-tab">
                                                                    <label className="header-tab__title">Guía de entrevista</label>
                                                                </Card.Body>
                                                            </Card>
                                                            <Card className="border-0">
                                                                <Card.Body className="pb-0 pt-3 card-body-tabs">
                                                                    <h6 style={{ fontStyle: "italic" }}>Inicie la entrevista para ver la guía</h6>
                                                                </Card.Body>
                                                            </Card>
                                                        </div>}
                                                    </Tab>
                                                }
                                                {(!this.state.isCandidate) &&
                                                    <Tab eventKey="archivos" title={<img src={imgFolder} alt="" className={this.state.seccionTab === "archivos" ? "secciones__iconos secciones__iconos--active" : "secciones__iconos  "} />} >
                                                        {this.state.scheduleId !== 0 &&
                                                            <Archivos schedule={this.state.scheduleId} objSesion={this.state.objSesion} />
                                                        }
                                                    </Tab>
                                                }
                                                {this.state.isCandidate === false &&
                                                    <Tab
                                                        eventKey="notas"
                                                        title={<img src={imgNota} alt="" className={this.state.seccionTab === "notas" ? "secciones__iconos secciones__iconos--active" : "secciones__iconos "} />}
                                                    >
                                                        {(this.state.habilitarNota && this.state.interviewId !== 0 && (this.state.estatusParticipante === 6 || this.state.estatusParticipante === 5)) ?
                                                         <Notas
                                                            handleNota={this.handleNota}
                                                            saveNote={this.saveOrUpdateNote}
                                                            objectNote={this.state.noteInterviewer}
                                                            isSaved={this.state.IsSavedNote}
                                                            isProcessSave={this.state.notaSaveProcess}
                                                        />:
                                                        <div>
                                                            <Card className="border-0">
                                                                <Card.Body className="header-tab">
                                                                    <label className="header-tab__title">Notas</label>
                                                                </Card.Body>
                                                            </Card>
                                                            <Card className="border-0">
                                                                <Card.Body className="pb-0 pt-3 card-body-tabs">
                                                                    <h6 style={{ fontStyle: "italic" }}>Inicie la entrevista para ver las notas</h6>
                                                                </Card.Body>
                                                            </Card>
                                                        </div>}
                                                    </Tab>
                                                }
                                                <Tab eventKey="chat" title={<img src={imgChat} alt="" className={(this.state.isCandidate === false ? "secciones__iconos " : "secciones__iconos ") + (this.state.seccionTab === "chat" ? " secciones__iconos--active " : "")} />} >
                                                    {(this.state.listParticipants.length > 0 && this.state.interviewId !== 0 && (this.state.estatusParticipante === 6 || this.state.estatusParticipante === 5)) ?
                                                        <Chat
                                                            interviewId={this.state.interviewId}
                                                            interviewers={this.state.listInterviewer}
                                                            isCandidate={this.state.isCandidate}
                                                            dataCandidate={this.state.dataCandidate}
                                                            participantId={this.state.participantId}
                                                            objSesion={this.state.objSesion}
                                                            listParticipants={this.state.listParticipants}
                                                        />:
                                                        <div>
                                                            <Card className="border-0">
                                                                <Card.Body className="header-tab">
                                                                    <label className="header-tab__title">Chat</label>
                                                                </Card.Body>
                                                            </Card>
                                                            <Card className="border-0">
                                                                <Card.Body className="pb-0 pt-3 card-body-tabs">
                                                                    <h6 style={{ fontStyle: "italic" }}>Inicie la entrevista o espere a que los participantes se conecten para ver el chat</h6>
                                                                </Card.Body>
                                                            </Card>
                                                        </div>
                                                    }
                                                </Tab>
                                                {(this.state.isCandidate) &&
                                                    <Tab eventKey="archivos" title={<img src={imgFolder} alt="" className={this.state.seccionTab === "archivos" ? "secciones__iconos secciones__iconos--active" : "secciones__iconos  "} />} >
                                                        {this.state.scheduleId !== 0 &&
                                                            <Archivos schedule={this.state.scheduleId} objSesion={this.state.objSesion} />
                                                        }
                                                    </Tab>
                                                }
                                            </Tabs>
                                        </div>
                                    </Col>
                                }
                            </Row> :
                            <Row className={this.state.estilocontenedor}>
                                <Col>
                                    {(this.state.validacionParticipantMessage !== "") &&
                                        <PageValidacion message={this.state.validacionParticipantMessage} irEntrevistas={() => this.IrEntrevistas()} />
                                    }
                                </Col>
                            </Row>
                        }
                    </Container>
                </div>
                {/* </div> */}
                {(this.state.habilitarModalEntrevistador) &&
                    <CalificarEntrevista handleModalEntrevistador={this.handleModalEntrevistador} show={this.state.mostrarModalEntrevistador} onHide={() => this.CerrarModal()} data={this.state.conclusionEntrevistador} />
                }
                {(this.state.habilitarModalCandidato) &&
                    <CierreEntrevista handleModalCandidato={this.handleModalCandidato} show={this.state.mostrarModalCandidato} onHide={() => this.CerrarModal()} data={this.state.conclusionCandidate} />
                }

                {this.state.errorConclusion ? <PageError onHide={this.handleCloseModalErrors} imgError={imagenError} msj="Ocurrió un error, inténtelo de nuevo. " /> : null}
                {this.state.exitoConclusion ? <PageError onHide={this.handleCloseModalErrors} imgError={imagenExito} msj="Conclusión guardada con éxito." /> : null}


            </React.Fragment>
        )

    }
}

export default IniciarVideo;