import { createFetch } from "../../lib/fecthObject";
import axios from "../../lib/peticionApi";
import { GetTokenVideoEntrevista } from "../../lib/cookieSession";
import { _SERVER } from "../../settings";
import { httpVerbTypes } from "../../utils/types/httpServices.js";
import { interviewStatusTypes } from "../../utils/types/interviewStatus";

/** 
 * Endpoint que permite cancelar una entrevista desde el dsahboard de entrevistas
* @param {number} id: Id de la entrevista a cancelar 
*/
export const calcelInterview = async ({ accessCode }) => {
  const token = GetTokenVideoEntrevista();
  const url = `${_SERVER}/api/Schedule/cancel`;
  const fetch = createFetch({
    method: httpVerbTypes.put,
    url,
    token,
    data: {
      accessCode,
      statusId: interviewStatusTypes.canceled
    },
  });
  const response = await axios(fetch);
  return response;
}