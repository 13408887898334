import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import StarRatings from 'react-star-ratings';
import Alert from 'react-bootstrap/Alert'
import './CalificarEntrevista.css';
class CalificarEntrevista extends Component {

    constructor(props) {
        super(props);
    }

    state = {
        registraCalificacionEntrevista: false,
        form: {
            id: 0,
            scoreCompatibility: this.props.data.scoreCompatibility,
            advantageHiring: this.props.data.advantageHiring,
            riskHiring: this.props.data.riskHiring,
            conclusion: this.props.data.conclusion
        },
        mostrarAlert: "modal-entrevistador__label--ocultar",
        mostrarAlert2: "modal-entrevistador__label--ocultar",
        mostrarAlert3: "modal-entrevistador__label--ocultar",
        mostrarAlertMessage:"modal-entrevistador__label--ocultar",
        colorStars: "#F8CB00",
    };

    cancelar = () => {
        this.props.onHide()
    }

    aceptar = () => {

        if (this.state.form.advantageHiring !== null && this.state.form.advantageHiring !== "" &&
            this.state.form.riskHiring !== null && this.state.form.riskHiring !== "" &&
            this.state.form.conclusion !== null && this.state.form.conclusion !== "" && this.state.form.scoreCompatibility >= 0)
        {
            this.props.handleModalEntrevistador(this.state.form);
            this.props.onHide();
        }
        else
        {
            if (this.state.form.advantageHiring === null || this.state.form.advantageHiring === "") {
                this.setState({
                    mostrarAlert: "",
                });
            }else{
                this.setState({
                    mostrarAlert: "modal-entrevistador__label--ocultar",
                });
            }

            if(this.state.form.riskHiring === null || this.state.form.riskHiring === ""){
                this.setState({
                    mostrarAlert2: "",
                });
            }else{
                this.setState({
                    mostrarAlert2: "modal-entrevistador__label--ocultar",
                });
            }
            if(this.state.form.conclusion === null || this.state.form.conclusion === ""){
                this.setState({
                    mostrarAlert3: "",
                });
            }else{
                this.setState({
                    mostrarAlert3: "modal-entrevistador__label--ocultar",
                });
            }

            if(this.state.form.scoreCompatibility <0){
                this.setState({
                    colorStars: "#FF0000",
                    form: {
                        scoreCompatibility: 5,
                    },
                    mostrarAlertMessage:""
                });
                setTimeout(() => {
                    this.setState({
                        colorStars: "#F8CB00",
                        form: {
                            scoreCompatibility: 0,
                        }
                    });
                }, 200);
            }
            else {
                this.setState({
                    colorStars: "#F8CB00",
                });
            }
        }
    }

    changeRating = (newRating) => {
        this.setState({
            form: {
                scoreCompatibility: newRating,
                advantageHiring: this.state.form.advantageHiring,
                riskHiring: this.state.form.riskHiring,
                conclusion: this.state.form.conclusion
            }
        });
    }

    handleChange = event => {
        this.setState({
            form: {
                ...this.state.form,
                [event.target.name]: event.target.value,
                scoreCompatibility: this.state.form.scoreCompatibility,
            }
        })
    }
    cerrarAlert=()=>{
        this.setState({
            mostrarAlertMessage:"modal-entrevistador__label--ocultar",
        });
    }
    render() {
        const { form } = this.state;
        return (

            <Modal {...this.props} aria-labelledby="contained-modal-title-vcenter" centered   >

                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className="titulo-modal modal-entrevistador__text--negritas">
                        Cierre de entrevista y conclusiones
                </Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-entrevistador">
                        <section className="ml-3 mr-3">
                            <div className="row">
                                <div className="col-sm-12">
                                    <Alert className="class-alert">
                                        <div className="row">
                                            <div className="col-sm-1">
                                                <FontAwesomeIcon icon={faInfoCircle} className="modal-entrevistador__icon" />
                                            </div>
                                            <div className="col-sm-11">
                                                <p className=" modal-entrevistador__text">
                                                    Es momento de revisar las conclusiones de tu entrevista. Por favor llena todos los puntos aquí marcados
                                                    para informar de tus apreciaciones al equipo de Capital Humano.
                                                </p>
                                                <p className="modal-entrevistador__text--negritas  modal-entrevistador__text">Nada de lo que aquí escribas será enviado al Candidato.</p>
                                            </div>
                                        </div>
                                    </Alert>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label className="modal-entrevistador__text--negritas text-titulos-seccion" >Compatibilidad</label>
                                        <label className="modal-entrevistador__text">¿Qué tan compatible es esta persona con los requerimientos del puesto?</label>

                                    </div>
                                    <div className="modal-entrevistador__stars mb-3">
                                        <StarRatings rating={this.state.form.scoreCompatibility} starRatedColor={this.state.colorStars} starHoverColor="#F8CB00" changeRating={this.changeRating} numberOfStars={5} name='scoreCompatibility' />
                                    </div>
                                    <div className={this.state.mostrarAlertMessage}>
                                        <Alert variant="danger" className="estiloAlert" onClose={() => this.cerrarAlert()} dismissible>
                                            <p>Marcar los campos requeridos.</p>
                                        </Alert>
                                    </div>

                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label className="modal-entrevistador__text--negritas text-titulos-seccion">Ventajas de contratación</label>
                                        <label className="modal-entrevistador__text">Son los atributos de la persona que fortalecen el equipo, fomentan la
                                            competitividad de la Organización y apoyan el desarrollo del Negocio.</label>
                                        <textarea className="modal-entrevistador__text-area" maxlength="950" name="advantageHiring" rows="4" cols="50" placeholder="ej. Se busca asesor comercial con experiencia en ventas tangibles" onChange={this.handleChange} value={this.state.form.advantageHiring}></textarea>
                                        <div className={this.state.mostrarAlert}>
                                            <label className="modal-entrevistador__label">campo requerido</label>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label className="modal-entrevistador__text--negritas text-titulos-seccion">Riesgos de contratación</label>
                                        <label className="modal-entrevistador__text"> Son los atributos de la persona que podrían implicar
                                            algunas dificultades para obtener los resultados de Negocio esperados.</label>
                                        <textarea className="modal-entrevistador__text-area" maxlength="950" name="riskHiring" rows="4" cols="50" placeholder="ej. Se busca asesor comercial con experiencia en ventas tangibles" onChange={this.handleChange} value={this.state.form.riskHiring}></textarea>
                                        <div className={this.state.mostrarAlert2}>
                                            <label className="modal-entrevistador__label">campo requerido</label>
                                        </div>
                               </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label className="modal-entrevistador__text--negritas text-titulos-seccion">Conclusiones</label>
                                        <label className="modal-entrevistador__text">Puedes escribir en este apartado las observaciones adicionales que tengas del candidato.</label>
                                        <textarea className="modal-entrevistador__text-area" maxlength="950" name="conclusion" rows="4" cols="50" placeholder="ej. Se busca asesor comercial con experiencia en ventas tangibles" onChange={this.handleChange} value={this.state.form.conclusion}></textarea>
                                        <div className={this.state.mostrarAlert3}>
                                            <label className="modal-entrevistador__label">campo requerido</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                </Modal.Body>
                <Modal.Footer>
                    <button className="mr-3 modal-footer__boton-cancelar text-titulos-seccion modal-entrevistador__text--negritas" onClick={this.cancelar} >Cancelar</button>
                    <button className="modal-footer__boton-aceptar text-titulos-seccion modal-entrevistador__text--negritas" onClick={this.aceptar}>Aceptar</button>
                </Modal.Footer>
            </Modal>
        );

    }
}
export default CalificarEntrevista;