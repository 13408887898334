import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import telefono from "../../images/telefono.svg";

function FinEntrevista(props) {
    return(
        <Card className="border-0 pt-0">
            <Card.Body className="pt-0">
                <Row className="finalizar">
                    <Col sm={12} className="pt-0">
                        <img className="finalizar__imagen" src={telefono} alt=""/>
                    </Col>
                    <Col sm={12} className="text-center">
                        <p className="finalizar__text">Llamada finalizada, hasta luego</p>
                        {(props.interviewInProcess) ?  <button className="finalizar__boton finalizar__boton--mright" onClick={props.goBackInterview} > Volver a ingresar</button> : null }
                        <button className="finalizar__boton" onClick={props.irEntrevistas} > Ir a mis entrevistas</button>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default FinEntrevista;